import Axios from 'axios';
import qs from "qs";
import React, { Component } from 'react';
import LS_SERVICE from '../../../utils/localStorage';
import {
  HANDLEVALIDANTMESSAGES,
  LOADER_RED,
  LOADER,
  USERTYPES,
  HOSPITALDEFAULTS,
  BMI_CALCULATE,
  BSA_CALCULATE,
  PATIENT_DATA,
  VITALS_GETALL,
  VITALS_GRAPH,
  PDF_CONFIG_VITALS_GRAPH,
  VITALS_FILTER_SEQUENCING_LIST,
  OBJECTIVE_VITAL_STORE_SS,
  OBJECTIVE_KEYFINDING_STORE_SS,
  KEYFINDINGS_FILTER_SEQUENCING_LIST,
  DISABLED_FUTURE_DATES,
  GET_VITALS_RANGE_CLASS,
  VISITS_BY_DOCTOR,
  KEY_FINDINGS_LIS,
  NEW_VITAL_ORDER,
  VITALS_FILTER_SEQUENCING_SAVE,
  KEYFINDINGS_PRINT_SAVE
} from '../../../utils/constant';
import {
  DatePicker,
  Radio,
  Select,
  Form,
  Checkbox,
  Input,
  Modal,
  Button,
  Tooltip,
  Badge,
  notification,
  Card
} from 'antd';
import { jQueryLabels, AntSelectLabels, AntClearLabels, AntDisabledLabels, FixedCta, onlynumbers } from "../../../utils/jQueryLabels";
import moment from "moment";
import Moment from "react-moment";
import { headerService$, practiceService } from "../../../utils/rxjs-sharing";
import VitalsFilters from "../../../components/vitals/vitals-filters";
import VitalsSS from "../../../components/vitals/vitals-ss";
import KeyFindings from "../../../components/vitals/key-findings";
import KeyFindingsNew from "../../../components/vitals/key-findings-new";
import KeyFindingsFilters from "../../../components/vitals/key-findings-filters";
import KeyFindingsChart from "../../../components/chart/key-findings-chart";
import KeyFindingsChartNew from "../../../components/chart/key-findings-chart-new";
import Chart from "../../../components/chart/chart";
import SubHeaderMain from '../../../components/subheader/subheadermain';
import debounce from 'lodash/debounce';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

class VitalsKeyFindings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patient : {
        hospital_id   : LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
        patient_id    : LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
        qms_token_id  : LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      },
      mhea_patient_id: LS_SERVICE.get('PATIENT_REG_DATA')?.registrationid ? LS_SERVICE.get('PATIENT_REG_DATA').registrationid : 0,
      hospital_id: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
      patient_id: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
      reference_id: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      allowEdit: props.allowEdit,
      isNurseLoggined: false,
      spin_loading: false,
      reasonModal: false,
      reasonChangeValue: 0,
      details: null,
      constants: null,
      visitVitals: [],
      visitVitalsKeyFindings: [],
      ss_configuration: LS_SERVICE.get('ss_configuration'),
      sourcescreen: props.sourcescreen,
      consult_type: props.consult_type,
      objective_data_loaded: false,
      vitals_data_formatted: false,
      visitVitalsFormatted: [],
      vitalFormValuesChanged: false,
      vitalViewModal: false,
      vitalNotInRange: [],
      vitals: [],
      vitalsFiltersLoaded: false,
      chartParamerModelVital: false,
      dateFormat: "YYYY-MM-DD HH:mm:ss",
      key_finding_date_time: moment(),
      calander_open: false,
      keyFindingNotInRange: [],
      keyFindings: [],
      keyFindingsFiltersLoaded: false,
      keyfindings_data_formatted: false,
      visitKeyFindingsFormatted: [],
      keyFindingViewModal: false,
      keyFindingsNewViewModal: false,
      chartParamerModel: false,
      keyFindingsModal: false,
      chartModal: false,
      keyFindingChartModal: false,
      keyFindingChartModalNew: false,

      range: [],
      chartGraphSettings: "",
      chartData: {},
      bloodSugarChartData: null,
      chartDataLoaded: false,
      chartGraphSettings: "",
      chartsTab: "2",
      chartsType: "all",
      chartHeading: "Vital",

      keyFindingsChartGraphSettings: "",
      vitalsRangeWithId: null,
      vitalsRange: {},
      key_findings_range: [],
      keyFindingsChartData: {},
      keyFindingsChartGraphSettings: "",
      keyFindingsChartDataLoaded: false,
      keyFindingsChartsTab: "2",
      keyFindingsChartsType: "all",
      keyFindingsChartHeading: "Key Findings",
      selected_key_findings: {},
      /* patientHeight: null,
      patientWeight: null, */
      keyFindingsDetails: [],
      keyFindingsNewDataFormatted: {},
      isKeyFindingsLoaded: false,
      kfChartData: {},
      new_vitals_order_are: [],
      findingGroupData: [],
      vital_graph_loaded: false
    }
    this.vitalFormRef = React.createRef();
    this.keyFindingFormRef = React.createRef();
    this.reasonFormRef = React.createRef();
    this.handleOnCompleteDebounced = debounce(this.handleOnComplete, 1000);

    this.reasonChange = [
      {
        value: 0,
        reason: 'Incorrect reading entered by patient'
      }, {
        value: 1,
        reason: 'Incorrect reading due to faulty machine'
      }, {
        value: 2,
        reason: 'Others'
      }
    ]


  }

  componentDidMount() {
    if (LS_SERVICE.has("hospitalConfigData") && LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      const hospitalConfigData = LS_SERVICE.get("hospitalConfigData");
      let hospitalConfigObj = hospitalConfigData.find((obj) => obj.hospital_id == this.state.hospital_id);
      if (hospitalConfigObj) {
        headerService$.soapConfig({
          soapConfig: hospitalConfigObj.opd_layout,
        }); // IMPORTANT TO HIT AS PER THE VIEW
      }
      this.getPatientData();
      jQueryLabels();
      FixedCta();
      onlynumbers();
    } else {
      this.setState({ loginRole: 'nurse' }, () => {
        this.getPatientData();
        jQueryLabels();
        FixedCta();
        onlynumbers();
      });
    }
    this.isNewDoctor();
  }

  isNewDoctor = () => {
    Axios.get(VISITS_BY_DOCTOR({ doctor_id: LS_SERVICE.get('staff_id') })).then(success => {
      let isNewDoctor = success?.data?.data; 
      if (isNewDoctor == undefined || isNewDoctor == null || typeof isNewDoctor == 'undefined') {
        isNewDoctor = 0;
      }
      if (parseInt(isNewDoctor) == 0) {
        this.setState({
          new_vitals_order_are: NEW_VITAL_ORDER
        })
        const PARAMS = {
          vitals: NEW_VITAL_ORDER
        };
        Axios.post(VITALS_FILTER_SEQUENCING_SAVE, PARAMS);
      }
    });
  }

  getPatientData = (_) => {
    const { reference_id } = this.state;
    var reference_type = LS_SERVICE.get('reference_type');
    Axios.get(PATIENT_DATA({ reference_id, reference_type })).then((success) => {
      const patient = {
        ...success.data.data.results,
        qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id,
      };
      //Check if nurse logged in and set doctor speciality
      if (this.state.isNurseLoggined) {
        var allDocSpecialities = LS_SERVICE.get('doctor_speciality_id');
        if (allDocSpecialities) {
          var returnValue = this.filterArray(success?.data?.data?.results?.doctor_id, allDocSpecialities);
          if (returnValue && returnValue.length > 0) {
            LS_SERVICE.set('speciality_id', returnValue[0]);
          }
        }
      }

      this.setState(
        {
          patient,
        },
        () => {
          this.handleObjectiveGetVitalsData();
          //this.handleVitalGraphData();
        }
      );
    });
  };

  handleVitalGraphData = async (_) => {
    const { patient_id, qms_token_id } = this.state.patient;

    const vitalGraphPromise = await Axios.get(VITALS_GRAPH({ patient_id }));
    const vitalPDFVitalGraphPromise = await Axios.get(
      PDF_CONFIG_VITALS_GRAPH({
        reference_type: LS_SERVICE.get('reference_type'),
        reference_id: qms_token_id,
      })
    );

    if (vitalGraphPromise.data.status) {
      const { data } = vitalGraphPromise;

      this.setState({
        chartData: data.details,
        bloodSugarChartData: data.blood_sugar_data,
        keyFindingsChartData: data.details,
        vitalsRange: Object.values(data.constants.range),
        vitalsRangeWithId: data.constants.range,
      });
    }

    if (vitalPDFVitalGraphPromise.data.status) {
      const { data } = vitalPDFVitalGraphPromise;

      this.setState({
        chartGraphSettings: data.data.details !== null ? (data.data.details.graph_settings !== null ? data.data.details.graph_settings : "") : "",
        keyFindingsChartGraphSettings:
          data.data.details !== null
            ? data.data.details.key_findings_graph_settings !== null
              ? data.data.details.key_findings_graph_settings
              : ""
            : "",
      });
    }
  };

  handleObjectiveGetVitalsData = async () => {
    const { patient_id } = this.state.patient;
    const objectiveGetAllPromise = await Axios.get(VITALS_GETALL({ patient_id }));
    if (objectiveGetAllPromise.data.status) {
      const { data } = objectiveGetAllPromise.data;
      const { details, constants } = data;

      await this.setState({
        details,
        visitVitals: Object.values(details.visitVitals),
        visitVitalsKeyFindings: Object.values(details.visitVitalsKeyFindings),
        constants,
        objective_data_loaded: true,
      });
      this.makeVitalsData();
      await this.handleVitalFilterData();
      this.fillFormData();

      await this.handleKeyFindingFilterData();
      this.makeKeyFindingsData();
    }
  }

  handleObjectiveGetKeyFindingsData = async () => {
    const { patient_id } = this.state.patient;
    const objectiveGetAllPromise = await Axios.get(VITALS_GETALL({ patient_id }));
    if (objectiveGetAllPromise.data.status) {
      const { data } = objectiveGetAllPromise.data;
      const { details, constants } = data;

      await this.setState({
        visitVitalsKeyFindings: Object.values(details.visitVitalsKeyFindings),
      });

      await this.handleKeyFindingFilterData();
      this.makeKeyFindingsData();
    }
  }

  makeVitalsData() {
    let vitalDetails = [];
    /* this.setState({
      patientHeight: null,
      patientWeight: null,
    }); */
    /* let { patient, patientHeight, patientWeight } = this.state; */
    let allVitalIds = [];
    this.state.constants?.vitals_details.map((v, k) => {
      allVitalIds.push(v.id);
      vitalDetails[v.id] = [];
      this.setState({ [v.id]: null });
    });
    vitalDetails["date"] = [];
    vitalDetails["source"] = [];
    vitalDetails["update_date"] = [];
    vitalDetails["source_name"] = [];
    allVitalIds.push("date");
    allVitalIds.push("source");
    allVitalIds.push("update_date");
    allVitalIds.push("source_name");

    if (Object.keys(this.state.visitVitals).length > 0) {
      let index = 0;
      this.state.visitVitals.map((temp_vitals, k) => {
        allVitalIds.forEach((j) => {
          if (vitalDetails[j]) vitalDetails[j][index] = temp_vitals[j] ?? "";
          /* if (
            j == "V001" &&
            patientHeight == null &&
            patient.age > HOSPITALDEFAULTS.age &&
            moment().diff(moment(temp_vitals.date[j]), "months") < 2 &&
            temp_vitals[j]
          )
            patientHeight = temp_vitals[j];
          if (
            j == "V002" &&
            patientWeight == null &&
            patient.age > HOSPITALDEFAULTS.age &&
            moment().diff(moment(temp_vitals.date[j]), "months") < 2 &&
            temp_vitals[j]
          )
            patientWeight = temp_vitals[j]; */
        });
        index++;
      });
    }
    /* this.setState({
      patientHeight: patientHeight,
      patientWeight: patientWeight,
      V001: patientHeight,
      V002: patientWeight,
    }); */
    let vitals_data = {};
    let newVitalEmptyArray = vitalDetails["date"] ? Array.from({ length: vitalDetails["date"].length }, (i) => "") : [];
    this.state.constants?.vitals_details.map((v, k) => {
      vitals_data[v.id] = vitalDetails[v.id] && vitalDetails[v.id].length > 0 ? vitalDetails[v.id] : newVitalEmptyArray;
    });
    vitals_data.date = vitalDetails["date"];
    vitals_data.source = vitalDetails["source"];
    vitals_data.update_date = vitalDetails["update_date"];
    vitals_data.source_name = vitalDetails["source_name"];
    this.setState({
      visitVitalsFormatted: vitals_data,
      vitals_data_formatted: true,
    });
  }

  fillFormData = () => {
    let latestvitals = {};
    console.log(this?.state?.visitVitalsFormatted);
    if (this?.state?.visitVitalsFormatted?.date?.length > 0) {
      let new_vitals_order = [];
      let VitalsConstants = this.state?.constants?.vitals_details;
      let VitalsOrder = this.state.vitals;

      if (VitalsOrder.length) {
        VitalsOrder.map((v, k) => {
          let obj = { display_order: v.display_order, vital_id: v.vital_id };
          let findex = VitalsConstants.findIndex((x) => x.vital_id == v.vital_id);
          if (findex !== -1) {
            obj.id = VitalsConstants[findex].id
            new_vitals_order.push(obj);
            if (VitalsConstants[findex].id == 'V005') {
              new_vitals_order.push({ id: 'V006' });
            }

          }
        });
      }
      if (new_vitals_order.length) {
        if (this?.state?.visitVitalsFormatted?.date?.length > 0 && moment().diff(this?.state?.visitVitalsFormatted?.date[0]) <= 86400000) {
          new_vitals_order.forEach((k) => {
            latestvitals[k.id] = this.state.visitVitalsFormatted[k.id][0];
            this.setState({
              [k.id]: this.state.visitVitalsFormatted[k.id][0]
            })
          });
        }else{
          new_vitals_order.forEach((k) => {
            if(k.id==="V001"){
              if(this.state.visitVitalsFormatted[k.id][0]){
                latestvitals[k.id] = this.state.visitVitalsFormatted[k.id][0];
                this.setState({
                  [k.id]: this.state.visitVitalsFormatted[k.id][0]
                })
              }
            }
            if(k.id==="V002"){
              if(this.state.visitVitalsFormatted[k.id][0]){
                latestvitals[k.id] = this.state.visitVitalsFormatted[k.id][0];
                this.setState({
                  [k.id]: this.state.visitVitalsFormatted[k.id][0]
                })
              }
            }
          });
        }
      }
    }
    this.setState({
      latestvitals
    })
  }

  handleVitalFilterData = async () => {
    const vitalFilterPromise = await Axios.get(VITALS_FILTER_SEQUENCING_LIST);

    if (vitalFilterPromise.data.status) {
      await this.setState({
        vitals: vitalFilterPromise.data.data,
        vitalsFiltersLoaded: true,
      });
    }
    AntSelectLabels();
  }

  handleKeyFindingFilterData = async () => {
    let doctor_id= LS_SERVICE.get('call_patient_detail') ? LS_SERVICE.get('call_patient_detail')?.doctor_id : LS_SERVICE.get('staff_id');
    const keyFindingFilterPromise = await Axios.get(KEYFINDINGS_FILTER_SEQUENCING_LIST({doctor_id:doctor_id}));

    if (keyFindingFilterPromise.data.status) {
      this.setState({
        keyFindings: keyFindingFilterPromise.data.data,
        keyFindingsFiltersLoaded: true,
      });
    }
  };

  makeKeyFindingsData() {
    let keyFindingDetails = [];
    let allKeyFindingsIds = [];
    if (this.state.constants && this.state.constants.findingGroupData) {
      Object.values(this.state.constants.findingGroupData).map((f, k) => {
        f.vital_sign.map((v, k2) => {
          allKeyFindingsIds.push(v.id);
          keyFindingDetails[v.id] = [];
        });
      });
    }
    keyFindingDetails["date"] = [];
    keyFindingDetails["source"] = [];
    keyFindingDetails["update_date"] = [];
    keyFindingDetails["source_name"] = [];
    allKeyFindingsIds.push("date");
    allKeyFindingsIds.push("source");
    allKeyFindingsIds.push("update_date");
    allKeyFindingsIds.push("source_name");

    if (Object.keys(this.state.visitVitalsKeyFindings).length > 0) {
      let index = 0;
      this.state.visitVitalsKeyFindings.map((temp_vitals, k) => {
        allKeyFindingsIds.forEach((j) => {
          if (keyFindingDetails[j]) keyFindingDetails[j][index] = temp_vitals[j] ?? "";
        });
        index++;
      });
    }

    let keyfindings_data = {};
    let newKeyFindingsEmptyArray = keyFindingDetails["date"] ? Array.from({ length: keyFindingDetails["date"].length }, (i) => "") : [];
    if(this.state.constants?.findingGroupData){
      Object.values(this.state.constants?.findingGroupData).map((f, k) => {
        f.vital_sign.map((v, k2) => {
          keyfindings_data[v.id] = keyFindingDetails[v.id] && keyFindingDetails[v.id].length > 0 ? keyFindingDetails[v.id] : newKeyFindingsEmptyArray;
        });
      });
    }

    keyfindings_data.date = keyFindingDetails["date"];
    keyfindings_data.source = keyFindingDetails["source"];
    keyfindings_data.update_date = keyFindingDetails["update_date"];
    keyfindings_data.source_name = keyFindingDetails["source_name"];

    this.setState({
      visitKeyFindingsFormatted: keyfindings_data,
      keyfindings_data_formatted: true,
    });
  }

  handleModalPopup = (e, popup, data = null) => {
    if (e !== null) e.preventDefault();
    const { isPracticing } = this.state;

    /* if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      if (!isPracticing && popup !== "chartModal" && popup !== "keyFindingChartModal") {
        this.setState({
          isPracticingModal: true,
        });
        return;
      }
    } */
    this.setState(
      {
        [popup]: !this.state[popup],
      },
      () => {
        const { keyFindingsModal, chartModal, keyFindingChartModal } = this.state;
        if (!keyFindingsModal) {
          if (this.keyFindingFormRef.current !== null) {
            this.keyFindingFormRef.current.resetFields();
          }
        }
        if (chartModal) {
          this.setState({
            chartDataLoaded: true,
            chartsType: data.type,
            chartHeading: data.name,
          });
        } else if (keyFindingChartModal) {
          this.setState({
            keyFindingsChartDataLoaded: true,
            keyFindingsChartsType: data.type,
            keyFindingsChartHeading: data.name,
            keyFindingsChartId: data.id ?? null,
          });
        }else if(popup === "reasonModal"){
          this.setState({
            reasonChangeValue: 0
          })

          if (this.reasonFormRef.current !== null) {
            this.reasonFormRef.current.resetFields();
          }
        }
      }
    );
  }

  handleModalChartPopup = async (e, popup, data = null) => {
    if (e !== null) e.preventDefault();

    await this.setState(
      {
        kfChartData: data
      },
      () => {
        console.log("handleModalChartPopup-->",data);
        this.setState({
          [popup]: !this.state[popup],
          keyFindingsChartsType: data.type,
          keyFindingsChartHeading: data.name,
          keyFindingsChartId: data.id ?? null,

        });
      }
    );
  }

  handleKFDataCopyToTemplate = async(copyToDSFormattedArr = null, appenedKFtHtml = null, selectedGraph = null) => {
    const { patient } = this.state;
    const { patient_id, qms_token_id } = patient;
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: qms_token_id,
      patient_id,
    };
    if(copyToDSFormattedArr!=null){
      PARAMS['detail']=qs.stringify(copyToDSFormattedArr);
    }
    if(selectedGraph!=null){
      PARAMS['selectedGraph']=qs.stringify(selectedGraph);
    }
    this.setState({ isKeyFindingsLoaded: false });
    const printAddResult = await Axios.post(KEYFINDINGS_PRINT_SAVE, qs.stringify(PARAMS));
    if (printAddResult.data.status) {
      this.setState({ isKeyFindingsLoaded: true, keyFindingsNewViewModal: false});
      notification.success({
        message: printAddResult.data.message,
        placement: "topRight",
      });
    }else{
      notification.error({
        message: printAddResult.data.message,
        placement: "topRight",
      });
    }

}

  handleCancelModalChartPopup(e, popup) {
    if (e !== null) e.preventDefault();
    this.setState({
      [popup]: !this.state[popup],
      keyFindingsChartsTab: "2",
    });
  }

  handleVitalsUpdate = async (vitals) => {
    await this.setState({
      vitals: vitals,
    });
    AntSelectLabels();
  };

  handleKeyFindingsUpdate = async (keyfindings) => {
    await this.setState({
      keyFindings: keyfindings,
    });
  };

  handleOnChangeNumbers = (event, min, max, decimal_value, type = "", isDate = "", date = "") => {
    console.log(event.currentTarget);
    let { name, value } = event.currentTarget;
    let vitalNotInRange = this.state.vitalNotInRange;
    //value = value.replace(decimal_value == 0 ? /\D/ : /[^0-9.]/g, "");
    if (decimal_value != 0 && value.includes(".")) {
      let fraction = value.split(".");
      let valueInt = parseInt(fraction[0]);
      fraction = "" + fraction[1];
      if (fraction.length != 0 && fraction.length > decimal_value) {
        fraction = fraction.substring(0, decimal_value);
        fraction = fraction && fraction != "" ? fraction : 0;
        value = valueInt + "." + fraction;
      }
    }
    var index = vitalNotInRange.indexOf(name);
    if (parseFloat(value) < min || parseFloat(value) > max) {
      if (index == -1) vitalNotInRange.push(name);
    } else {
      if (index != -1) vitalNotInRange.splice(index, 1);
    }

    this.setState(
      {
        [name]: value,
        vitalNotInRange,
        vitalFormValuesChanged: true
      },
      () => {
        this.vitalFormRef.current.setFieldsValue({
          [name]: value,
        });
        this.handleBmiCalculate();
      }
    );
    this.handleOnCompleteDebounced(event);
  };

  handleOnComplete = (event) => {
    console.log(event.target);
    let { name, value } = event.target;

    var oldFormValue = this.state.visitVitals.length > 0 ? this.state.visitVitals[0][name] : "";

    console.log('handleOnComplete', this.state.visitVitals[0][name] );
    if(oldFormValue != "" && oldFormValue != value ){
      this.setState({ reasonModal: true });
      this.setState({
        reasonChangeValue: 0
      })

      if (this.reasonFormRef.current !== null) {
        this.reasonFormRef.current.resetFields();
      }
    }
  };

  handleOnChangeText = (event, min, max, decimal_value, type = "", isDate = "", date = "") => {
    let { name, value } = event.currentTarget;
    let vitalNotInRange = this.state.vitalNotInRange;
    this.setState(
      {
        [name]: value,
        vitalNotInRange,
        vitalFormValuesChanged: true
      },
      () => {
        this.vitalFormRef.current.setFieldsValue({
          [name]: value,
        });
        this.handleBmiCalculate();
      }
    );
    this.handleOnCompleteDebounced(event);
  };

  handleOnChangeNumbersKeyFinding = (event, min, max, decimal_value, type = "", isDate = "", date = "") => {
    let { name, value } = event.currentTarget;
    let keyFindingNotInRange = this.state.keyFindingNotInRange;
    //value = value.replace(decimal_value == 0 ? /\D/ : /[^0-9.]/g, "");
    if (decimal_value != 0 && value.includes(".")) {
      let fraction = value.split(".");
      let valueInt = parseInt(fraction[0]);
      fraction = "" + fraction[1];
      if (fraction.length != 0 && fraction.length > decimal_value) {
        fraction = fraction.substring(0, decimal_value);
        fraction = fraction && fraction != "" ? fraction : 0;
        value = valueInt + "." + fraction;
      }
    }
    var index = keyFindingNotInRange.indexOf(name);
    if (parseFloat(value) < min || parseFloat(value) > max) {
      if (index == -1) keyFindingNotInRange.push(name);
    } else {
      if (index != -1) keyFindingNotInRange.splice(index, 1);
    }

    this.setState(
      {
        [name]: value,
        keyFindingNotInRange,
      },
      () => {
        this.keyFindingFormRef.current.setFieldsValue({
          [name]: value,
        });
      }
    );
  };

  handleBmiCalculate = (_) => {
    let { V001, V002, /* patientHeight, patientWeight, */ V014, V015 } = this.state;


    V001 = V001 !== null ? V001 : '';
    V002 = V002 !== null ? V002 : '';
    if (V001 > 0 && V002 > 0) {
      let val = "";
      if(this.state.vitals.find((o) => o.vital_id == 3)){
        val = BMI_CALCULATE(V001, V002);
      }

      let bsa = "";
      if(this.state.vitals.find((o) => o.vital_id == 15)){
        bsa = BSA_CALCULATE(V001, V002);
      }

      this.setState(
        {
          V003: val,
          V017: bsa,
        },
        () => {
          AntSelectLabels();
        }
      );

      this.vitalFormRef.current.setFieldsValue(
        {
          V003: val,
          V017: bsa,
        },
        () => {
          AntSelectLabels();
        }
      );

      AntSelectLabels();
    } else {
      this.setState(
        {
          V003: "",
          V017: "",
        },
        () => {
          AntSelectLabels();
        }
      );

      this.vitalFormRef.current.setFieldsValue({
        V003: "",
        V017: "",
      });

      AntSelectLabels();
    }
  };

  getVitalFFormItem() {
    let new_vitals_order = [];
    let VitalsConstants = this.state?.constants?.vitals_details;
    let VitalsOrder = this.state.vitals;

    if (VitalsOrder.length) {
      VitalsOrder.map((v, k) => {
        let obj = { display_order: v.display_order, vital_id: v.vital_id };
        let findex = VitalsConstants.findIndex((x) => x.vital_id == v.vital_id);
        if (findex !== -1) {
          new_vitals_order.push(obj);
        }
      });
    }

    if (new_vitals_order.length <= 0) {
      new_vitals_order =this.state.new_vitals_order_are
    }

    if (new_vitals_order.length && this.state.latestvitals) {
      let tabIndx = 0;
      return (
        <Form
          layout='vertical'
          className='emrfrm'
          ref={this.vitalFormRef}
          initialValues={this.state.latestvitals}
          validateMessages={HANDLEVALIDANTMESSAGES}
          onFinish={this.handleVitalAddSubmit}
        >
          <div className='row mt-2'>
            {new_vitals_order.map((v, k) => {
              let findex = VitalsConstants.findIndex((x) => x.vital_id == v.vital_id);
              if (findex !== -1) {
                tabIndx = tabIndx + 1;
                let vital = VitalsConstants[findex];
                if (vital.id == "V006") return null;
                else if (vital.id == "V005")
                  return (
                    <div className="col-2 antInputItem multi-col" style={{ marginBottom: 1 + `rem` }}>
                      <Form.Item
                        name="bp"
                        label="Blood Pressure"
                        className='vital_bp_color'
                        rules={[
                          {
                            required: vital.required,
                            type: "number",
                            min: vital.min,
                            max: vital.max,
                            transform: function (value) {
                              return value === "" || value === undefined ? value : +value;
                            },
                          },
                        ]}
                      >
                        <div className="multi-input-col-inner">
                          <div className="ant-input-wrapper ant-input-group">
                            <div className="ant-input">
                              <Input
                                name="V006"
                                data-vitalid="6"
                                tabIndex={tabIndx}
                                autoComplete="off"
                                maxLength={vital.maxlength}
                                min={vital.min}
                                max={vital.max}
                                className={GET_VITALS_RANGE_CLASS(this.state.V006, this.state.constants.vitals_range.V006)}
                                value={this.state.V006}
                                onChange={(e) => {
                                  this.handleOnChangeNumbers(e, vital.min, vital.max, vital.decimal_value);
                                }}
                              />
                              {tabIndx = tabIndx + 1,
                                <Input
                                  name="V005"
                                  data-vitalid="5"
                                  tabIndex={tabIndx}
                                  autoComplete="off"
                                  maxLength={vital.maxlength}
                                  min={vital.min}
                                  max={vital.max}
                                  className={`multi ${GET_VITALS_RANGE_CLASS(this.state.V005, this.state.constants.vitals_range.V005)}`}
                                  value={this.state.V005}
                                  onChange={(e) => {
                                    this.handleOnChangeNumbers(e, vital.min, vital.max, vital.decimal_value);
                                  }}
                                />
                              }
                            </div>

                            <span className="ant-input-group-addon">mmHg</span>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )
                else if (vital.vital_type == 'T')
                  return (
                    <div className={`col-2 antInputItem multi-col customcssvitalform`} style={{ marginBottom: 1 + `rem` }}>
                      <Form.Item
                        name={vital.id}
                        label={vital.display_name_short}
                        rules={[
                          {
                            required: vital.required,
                          },
                        ]}
                      >
                        <div className="multi-input-col-inner">
                          <div className="ant-input-wrapper ant-input-group">
                            <div className="ant-input">
                              <Input
                                name={vital.id}
                                autoComplete="off"
                                data-vitalid={vital.vital_id}
                                tabIndex={tabIndx}
                                value={this.state[vital.id]}
                                onChange={(e) => {
                                  this.handleOnChangeText(e, vital.min, vital.max, vital.decimal_value);
                                }}
                              />
                            </div>
                            <span className="ant-input-group-addon">{vital.display_value}</span>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )
                else
                  return (
                    <div className={`col-2 antInputItem multi-col customcssvitalform ${vital.id == "V003" || vital.id == "V017" ? 'disabled' : ''}`} style={{ marginBottom: 1 + `rem` }}>
                      <Form.Item
                        name={vital.id}
                        label={vital.display_name_short}
                        rules={[
                          {
                            required: vital.required,
                            type: "number",
                            min: vital.min,
                            max: vital.max,
                            transform: function (value) {
                              return value === "" || value === undefined || value === null ? '' : +value;
                            },
                          },
                        ]}
                      >
                        <div className="multi-input-col-inner">
                          <div className="ant-input-wrapper ant-input-group">
                            <div className="ant-input">
                              <Input
                                /* addonAfter={vital.display_value} */
                                name={vital.id}
                                autoComplete="off"
                                maxLength={vital.maxlength}
                                className={vital.id != 'V001' && vital.id != 'V002' ? GET_VITALS_RANGE_CLASS(this.state[vital.id], this.state.constants.vitals_range[vital.id]) : ''}
                                data-vitalid={vital.vital_id}
                                tabIndex={tabIndx}
                                value={this.state[vital.id]}
                                onChange={(e) => {
                                  this.handleOnChangeNumbers(e, vital.min, vital.max, vital.decimal_value);
                                }}
                                disabled={vital.id == "V003" || vital.id == "V017"}
                              />
                            </div>
                            <span className="ant-input-group-addon">{vital.display_value}</span>
                          </div>
                        </div>
                      </Form.Item>
                    </div>
                  )
              }
            })}
          </div>
        </Form>
      );
    }
  }

  disabledDate(current) {
    return current && current > moment();
  }

  setDateTimeHandler = (date) => {
    if (date !== null) {
      this.setState({
        key_finding_date_time: moment(date),
      });
    }
  };

  getKeyFindingFFormItem() {
    let KeyFindingsConstants = this.state?.constants?.findingGroupData;
    let keyFindingsOrder = this.state.keyFindings;
    let new_key_findings_order = [];
    if (keyFindingsOrder.length) {
      KeyFindingsConstants = Object.values(KeyFindingsConstants);
      keyFindingsOrder.map((fo, k2) => {
        let obj = { display_order: fo.display_order, finding_group_id: fo.finding_group_id, vital_sign: [] };
        let at_least_one_av = false;
        let findex = KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
        if (findex !== -1) {
          fo.vital_sign.map((v, k) => {
            let obj_vital_sign = { ...v };
            let findex2 = KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
            if (findex2 !== -1) {
              at_least_one_av = true;
              obj.vital_sign.push(obj_vital_sign);
            }
          });
        }
        obj.one_value_available = at_least_one_av;
        new_key_findings_order.push(obj);
      });
    }
    let one_group_avail = false;
    new_key_findings_order.forEach((data) => {
      if (!one_group_avail) {
        one_group_avail = data.one_value_available;
      }
    });
    if (new_key_findings_order.length && one_group_avail) {
      return (
        <Form
          layout='vertical'
          className='emrfrm'
          ref={this.keyFindingFormRef}
          validateMessages={HANDLEVALIDANTMESSAGES}
          onFinish={this.handleKeyFindingAddSubmit}
        >
          {/* <div className="row key__finding">
            <div className="col-4 center__align">
              <div className={`form-group ${this.state.key_finding_date_time ? "hasdata" : ""}`}>
                <DatePicker
                  id="key_finding_date_time"
                  name="key_finding_date_time"
                  format="MMM, DD YYYY hh:mm A"
                  disabledDate={this.disabledDate}
                  showNow={false}
                  placeholder={""}
                  allowClear
                  defaultPickerValue={this.state.key_finding_date_time}
                  showTime={{ defaultValue: moment() }}
                  defaultValue={this.state.key_finding_date_time}
                  value={this.state.key_finding_date_time}
                  className="form-control datetimepicker"
                  onOk={this.setDateTimeHandler}
                />
                <label htmlFor="key_finding_date_time">Select Date Time</label>
              </div>
            </div>
          </div> */}
         {new_key_findings_order.map((fo, k2) => {
            {
              let findex = KeyFindingsConstants.findIndex((x) => x.id == fo.finding_group_id);
              if (findex !== -1) {
                if (fo.one_value_available) {
                  /* const __name = KeyFindingsConstants[findex].name.replace(/\s+/g, "__").toLowerCase(); */
                  return (
                    <React.Fragment key={k2}>
                      <h2 className="model_head mt-3 pt-0">{KeyFindingsConstants[findex].name}</h2>
                      <div className="row model_bg_blue">
                        {fo.vital_sign.map((v, k) => {
                          let findex2 = KeyFindingsConstants[findex].vital_sign.findIndex((x) => x.vital_id == v.vital_id);
                          if (findex2 !== -1) {
                            let vital = KeyFindingsConstants[findex].vital_sign[findex2];
                            return (
                              <div className="col-4 antInputItem" key={k}>
                                <Form.Item
                                  key={v.vital_id}
                                  /* id={`${__name}_${vital.id}`} */
                                  name={vital.id}
                                  label={vital.display_name}
                                  rules={[
                                    {
                                      required: vital.required,
                                      type: "number",
                                      min: vital.min,
                                      max: vital.max,
                                      transform: function (value) {
                                        return value === "" || value === undefined ? value : +value;
                                      },
                                    },
                                  ]}
                                >
                                  <Input
                                    addonAfter={vital.display_value}
                                    name={vital.id}
                                    /* id={`${__name}_${vital.id}`} */
                                    autoComplete="off"
                                    maxLength={vital.maxlength}
                                    data-vitalid={vital.vital_id}
                                    value={this.state[vital.id]}
                                    onChange={(e) => {
                                      this.handleOnChangeNumbersKeyFinding(
                                        e,
                                        vital.min,
                                        vital.max,
                                        vital.decimal_value,
                                        KeyFindingsConstants[findex].name,
                                        "1",
                                        ""
                                      );
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </React.Fragment>
                  );
                }
              }
            }
          })}
        </Form>
      );
    }
  }

  handleVitalAddSubmitDisabled = (_) => {
    if (this.state.constants && this.state.constants.vitals_details) {
      var emptyCount = 0;

      let new_vitals_order = [];
      let VitalsConstants = this.state.constants.vitals_details;
      let VitalsOrder = this.state.vitals;

      if (VitalsOrder.length) {
        VitalsOrder.map((v, k) => {
          let obj = {};
          let findex = VitalsConstants.findIndex((x) => x.vital_id == v.vital_id);
          if (findex !== -1) {
            obj.id = VitalsConstants[findex].id
            new_vitals_order.push(obj);
            if (VitalsConstants[findex].id == 'V005') {
              new_vitals_order.push({ id: 'V006' });
            }
          }
        });
      }

      new_vitals_order.map((v, k) => {
        if (parseFloat(this.state[v.id]) == 0 && v.id !='V018') emptyCount++;
      });

      if (emptyCount > 0) return true;

      if (this.state.V005 != undefined && this.state.V005 != null && this.state.V005 != "" && (this.state.V006 == "" || this.state.V006 == null)) return true;

      if (this.state.V006 != undefined && this.state.V006 != null && this.state.V006 != "" && (this.state.V005 == "" || this.state.V005 == null)) return true;

      if (this.state.vitalNotInRange.length > 0) return true;

      return false;
    } else return false;
  };

  handleKeyFindingModalButtonDisabled = (_) => {

    if (this.state.keyFindingNotInRange.length > 0) return true;

    return false;
  };

  handleVitalAddSubmit = async (_) => {
    if (this.handleVitalAddSubmitDisabled()) return;
    await this.vitalFormRef.current.validateFields().then(async values => {
      this.setState({
        spin_loading: !this.state.spin_loading
      })
  
      let vitalsData = values;

      let postdata = {};
      for (var [key, value] of Object.entries(vitalsData)) {
        if (key != 'bp') {
          postdata[key] = value;
        }
      }
      postdata.V005 = this.state.V005 || "";
      postdata.V006 = this.state.V006 || "";
      postdata.V003 = this.state.V003 || "";
      postdata.V017 = this.state.V017 || "";
      if (postdata.V014 == "" && typeof postdata.V014 != "undefined" && postdata.V015 == "" && typeof postdata.V015 != "undefined") {
        postdata.V003 = "";
      }
      const { patient } = this.state;
      const { patient_id, qms_token_id } = patient;
      const { reasonChangeValue } = this.state;
      let role = LS_SERVICE.get("user_type") == USERTYPES.doctor ? 'Doctor' : LS_SERVICE.get("user_type") == USERTYPES.nurse ? 'Nurse' : LS_SERVICE.get("user_type") == USERTYPES.superadmin ? 'SuperAdmin' : LS_SERVICE.get("user_type") == USERTYPES.admin ? 'Admin' : null;
      const PARAMS = {
        vitals_data: postdata,
        reference_type: LS_SERVICE.get('reference_type'),
        reference_id: qms_token_id,
        patient_id,
        speciality_id: LS_SERVICE.get('speciality_id'),
        hospital_id: LS_SERVICE.get('slot_hospital_id'),
        reason: reasonChangeValue !== 2 ? this.reasonChange[reasonChangeValue].reason : this.reasonFormRef.current.getFieldsValue(true).reason,
        created_by_name: LS_SERVICE.get("profile")?.data?.full_name || "Dr. " + LS_SERVICE.get("staff_name") || null,
        created_by_role: role,
        updated_by_name: LS_SERVICE.get("profile")?.data?.full_name || "Dr. " + LS_SERVICE.get("staff_name") || null,
        updated_by_role: role,
      };

      const vitalAddPromise = await Axios.post(OBJECTIVE_VITAL_STORE_SS, qs.stringify(PARAMS));
      if (vitalAddPromise.status) {
        this.handleObjectiveGetVitalsData();
        await this.handleVitalGraphData();
        if(this.state.reasonModal){
          this.handleModalPopup(null, 'reasonModal');
        }
      }
      this.setState({
        spin_loading: !this.state.spin_loading
      })
    })
      .catch(errorInfo => {
      });
  }

  handleKeyFindingAddSubmit = async (_) => {
    if (this.handleKeyFindingModalButtonDisabled()) return;

    let keyFindingsData = this.keyFindingFormRef.current.getFieldsValue(true);
    let keyFindingsPostData = {};

    for (var [key, value] of Object.entries(keyFindingsData)) {
      if (keyFindingsPostData[key] == undefined && key != 'vitalsType' && key != 'bp') {
        if (value != null) {
          if (value.charAt(0) == '.') {
            value = '0' + value
          }
        }
        else {
          value = ''
        }
        keyFindingsPostData[key] = value;
      }
    }

    let datetime_keyfindings = moment(this.state.key_finding_date_time).format(this.state.dateFormat);

    const { patient } = this.state;
    const { patient_id, qms_token_id } = patient;
    const PARAMS = {
      key_findings_data: keyFindingsPostData,
      key_findings_date: datetime_keyfindings,
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: qms_token_id,
      patient_id,
    };

    this.setState({
      spin_loading: true,
    });

    const keyFindingAddPromise = await Axios.post(OBJECTIVE_KEYFINDING_STORE_SS, qs.stringify(PARAMS));

    if (keyFindingAddPromise.status) {
      this.handleObjectiveGetKeyFindingsData();
      this.handleVitalGraphData();
      this.handleModalPopup(null, "keyFindingsModal");
      const { data } = keyFindingAddPromise;
      notification.success({
        message: data.message,
        placement: "topRight",
      });
      this.setState({
        spin_loading: false,
      });
    }

  }

  handleKeyFindingFormSubmit = (_) => {
    if (this.keyFindingFormRef.current !== null) {
      this.keyFindingFormRef.current.submit();
    }
  }

  handleKeyFindingModalClear = (_) => {

    this.keyFindingFormRef.current.resetFields();

    AntClearLabels();

  };

  handleVitalChartClick = async (e) => {
    if (this.state.vitalFormValuesChanged) {
      if (this.vitalFormRef.current) {
        await this.handleVitalAddSubmit();
        this.setState({
          vitalFormValuesChanged: false
        })
      }
    } else {
      if(!this.state.vital_graph_loaded){
        await this.handleVitalGraphData();
        this.setState({
          vital_graph_loaded: true
        })
      }
    }
    this.handleModalPopup(e, "chartModal", {
      type: "all",
      name: "Trend Graphs",
    })
  }

  handleViewAll = async () => {
    if (this.state.vitalFormValuesChanged) {
      if (this.vitalFormRef.current) {
        await this.handleVitalAddSubmit();
        this.setState({
          vitalFormValuesChanged: false
        })
      }
    } else {
      if(!this.state.vital_graph_loaded){
        await this.handleVitalGraphData();
        this.setState({
          vital_graph_loaded: true
        })
      }
    }
    this.setState({ vitalViewModal: true })
  }

  async componentWillUnmount() {
    await this.storeVitalDetails();
  }

  async storeVitalDetails() {
    if (this.state.vitalFormValuesChanged) {
      if (this.vitalFormRef.current) {
        await this.handleVitalAddSubmit();
      }
    }
    this.setState({
      vitalFormValuesChanged: false
    })
  }

  handleGraphClick = (e, type = "2") => {
    this.setState(
      {
        chartDataLoaded: false,
        chartsTab: type,
      },
      () => this.setState({ chartDataLoaded: true })
    );
  };

  handleKeyGraphClick = (e, type = "2") => {
    this.setState(
      {
        keyFindingsChartDataLoaded: false,
        keyFindingsChartsTab: type,
      },
      () => this.setState({ keyFindingsChartDataLoaded: true })
    );
  };

  handleRangeChange = (dates) => {
    this.setState(
      {
        range: dates !== null ? dates : [],
      },
      () => {
        this.handleDateRangeGraphData(dates == null);
      }
    );
  };

  handleDateRangeGraphData = async (reset = false) => {
    const { patient_id, range } = this.state;

    const PARAMS = {
      patient_id,
      start_date: !reset ? moment(range[0]._d).format("YYYY-MM-DD HH:mm:ss") : undefined,
      end_date: !reset ? moment(range[1]._d).format("YYYY-MM-DD HH:mm:ss") : undefined,
    };

    const vitalGraphRangePromise = await Axios.get(VITALS_GRAPH(PARAMS));

    if (vitalGraphRangePromise.data.status === "success") {
      const { data } = vitalGraphRangePromise;

      this.setState(
        {
          chartDataLoaded: false,
          chartData: data.details,
          bloodSugarChartData: data.blood_sugar_data,
          vitalsRange: Object.values(data.constants.range),
        },
        () =>
          this.setState({
            chartDataLoaded: true,
          })
      );
    }
  };

  handleKeyFindingsRangeChange = (dates) => {
    this.setState(
      {
        key_findings_range: dates !== null ? dates : [],
      },
      () => {
        this.handleKeyFindingsDateRangeGraphData(dates == null);
      }
    );
  };

  handleKeyFindingsDateRangeGraphData = async (reset = false) => {
    const { patient_id, key_findings_range } = this.state;

    const PARAMS = {
      patient_id,
      start_date: !reset ? moment(key_findings_range[0]._d).format("YYYY-MM-DD HH:mm:ss") : undefined,
      end_date: !reset ? moment(key_findings_range[1]._d).format("YYYY-MM-DD HH:mm:ss") : undefined,
    };

    const vitalGraphRangePromise = await Axios.get(VITALS_GRAPH(PARAMS));

    if (vitalGraphRangePromise.data.status === "success") {
      const { data } = vitalGraphRangePromise;

      this.setState(
        {
          keyFindingsChartDataLoaded: false,
          keyFindingsChartData: data.details,
          vitalsRange: Object.values(data.constants.range),
        },
        () =>
          this.setState({
            keyFindingsChartDataLoaded: true,
          })
      );
    }
  };

  chartStateUpdate = (data) => {
    this.setState({
      chartGraphSettings: data,
    });
  };

  chartStateUpdateKeyFindigs = (data) => {
    this.setState({
      keyFindingsChartGraphSettings: data,
    });
  };

  getKeyFindingNew(start_date = moment(moment().format()).subtract(1, 'month').format(), end_date = moment().format()) {
    this.setState({ keyFindingsNewViewModal: true });
    const { mhea_patient_id, isKeyFindingsLoaded } = this.state;
    this.setState({ isKeyFindingsLoaded: false });
    let data = {
      facility_id: LS_SERVICE.get('FACILITY_ID'),
      registration_id: mhea_patient_id
    }

    if (start_date != '' && end_date != '') {
      data.fromDate = start_date;
      data.toDate = end_date;
    }
    Axios.post(KEY_FINDINGS_LIS, data)
      .then(async res => {
        await this.setState({ keyFindingsNewDataFormatted: res.data.data }
          , () => {
            this.setState({ isKeyFindingsLoaded: true })
          });
      }).catch(err => {
        console.log("err>>>>>>>>>>", err);
      })
  }

  async handleClickDuration(duration_type, type) {
    this.setState({ keyFindingsChartsTab: type });
    if (duration_type == 'day') {
      let end_date = moment().format();
      let start_date = moment(end_date).subtract(1, 'day').format();
      await this.getKeyFindingNew(start_date, end_date);
    }

    if (duration_type == 'week') {
      let end_date = moment().format();
      let start_date = moment(end_date).subtract(1, 'week').format();
      await this.getKeyFindingNew(start_date, end_date);
    }

    if (duration_type == 'month') {
      let end_date = moment().format();
      let start_date = moment(end_date).subtract(1, 'month').format();
      await this.getKeyFindingNew(start_date, end_date);
    }

    if (duration_type == 'year') {
      let end_date = moment().format();
      let start_date = moment(end_date).subtract(1, 'year').format();
      await this.getKeyFindingNew(start_date, end_date);
    }
  }
  handleAntRadioChange = e => {
    this.setState({
      reasonChangeValue: e.target.value,
    });
  };


  handleReasonFormSubmit = async v => {
    await this.handleVitalAddSubmit();
    console.log("handleReasonFormSubmit")
  }

  selectKeyFindingGraph = (e, type, data = null, ch = null) => {
    if (type === "keyFindingGraph") {
        this.setState(prevState => {
            const { selected_key_findings } = prevState;

            // Create a new object with the updated key findings
            let updatedKeyFindings;

            if (selected_key_findings.hasOwnProperty(ch)) {
                // Key exists, so remove it
                updatedKeyFindings = { ...selected_key_findings };
                delete updatedKeyFindings[ch];
            } else {
                // Key does not exist, so add it
                updatedKeyFindings = {
                    ...selected_key_findings,
                    [ch]: data
                };
            }

            return {
                selected_key_findings: updatedKeyFindings
            };
        }, () => {
            console.log("selectKeyFindingGraph====>", this.state.selected_key_findings);
            console.log("selectKeyFindingGraph====>", ch);
        });
    }
  }

  

  render() {
    const {
      selected_key_findings,
      objective_data_loaded,
      patient,
      latestvitals,
      ss_configuration,
      consult_type,
      sourcescreen
    } = this.state;
    var show_graph = false;
    if (this?.state?.visitVitalsFormatted?.date?.length > 0) {
      show_graph = true;
    }

    var show_graph_key_findings = false;
    if (this?.state?.visitKeyFindingsFormatted?.date?.length > 0) {
      show_graph_key_findings = true;
    }
    return (
      <div className='container-fluid px-0 mt-2 mb-0'>
        {objective_data_loaded && patient !== null ? (
          <>
            <div className='row d-flex align-items-center'>
              {
                sourcescreen != 'singlescreen'
                  ||
                  (sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 1 && o.sub_items.some(p => ((p?.id === 1 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
                  ?
                  <>
                    <div className='col-auto'>
                      <span className='text-primary mb-0' style={{ fontSize: '1rem', fontWeight: '500' }}>TODAY'S VITALS</span>
                      {latestvitals && Object.values(latestvitals).length > 0 ? <span className='text-muted pl-3' style={{ fontSize: '12px' }}>{moment(this.state.visitVitalsFormatted.date[0]).format('DD MMM, yyyy | h:mm A')}</span> : null}
                      {/* CORRECTION MADE BY VEENA */}
                    </div>
                    <div className='col-auto pl-3'>
                      {this.state.vitalsFiltersLoaded && show_graph == true ? (
                        <button type="button" class="btn top-chart-btn p-0 mr-2" onClick={(e) => this.handleVitalChartClick(e)}><i class="icon-chart"></i></button>
                      ) : null}
                      {this.state.vitals_data_formatted > 0 ? (
                        <a className='text-primary ml-2' onClick={() => this.handleViewAll()}><u>View All</u></a>
                      ) : null}
                    </div>
                  </>
                  :
                  null
              }
              {/* {
                  sourcescreen != 'singlescreen' 
                  ||
                  (sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 1 && o.sub_items.some(p => ((p?.id === 2 && p?.configuration?.[consult_type+'_screen']?.selected == 'Y'))))))
                ?
                  <div className='col d-flex justify-content-end align-items-center '>
                    <div className='col-auto'>
                      <h6 className='text-dark mb-0'>Key Findings</h6>
                    </div>
                    <div className='col-auto pr-3'>
                      {this.state.keyFindingsFiltersLoaded && show_graph_key_findings == true ? ( 
                        <button type="button" class="btn top-chart-btn p-0 mr-2" onClick={(e) =>
                          this.handleModalPopup(e, "keyFindingChartModal", {
                            type: "all",
                            name: "Key Findings Trend Graphs",
                          })
                        }><i class="icon-chart"></i></button>
                      ) : null}
                      {this.state.keyfindings_data_formatted > 0 ? ( 
                        <a className='text-primary ml-2' onClick={() => { this.setState({ keyFindingViewModal: true }) }}><u>View All</u></a>
                      ) : null}
                    </div>
                    <div className='col-auto pr-5'>
                      {this.state.allowEdit && ( 
                        <div class="float-right">
                          <a class="btn btn-outline-primary small px-2" id="key_find" onClick={(e) => this.handleModalPopup(e, "keyFindingsModal")}>+ KEY FINDINGS</a>
                        </div> 
                      )}
                    </div>
                  </div>
                :
                  null
                } */}
              {
                sourcescreen != 'singlescreen'
                  ||
                  (sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 1 && o.sub_items.some(p => ((p?.id === 1 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y')))))) ?

                  <div className='col d-flex justify-content-end align-items-center '>
                    <div className='col-auto'>
                      <h6 className='text-dark mb-0'>Key Findings</h6>
                    </div>
                    <div className='col-auto pr-3'>
                      <a className='text-primary ml-2' onClick={() => this.getKeyFindingNew()}><u>View All</u></a>
                    </div>
                  </div>
                  : null
              }



            </div>
            {
              sourcescreen != 'singlescreen'
                ||
                (sourcescreen == 'singlescreen' && ss_configuration.some(o => (o?.id === 1 && o.sub_items.some(p => ((p?.id === 1 && p?.configuration?.[consult_type + '_screen']?.selected == 'Y'))))))
                ?
                <>
                  {/* {this.state.vitalsFiltersLoaded ? this.getVitalFFormItem() : null} */}
                  {this.getVitalFFormItem()}
                </>
                :
                null
            }

            {/* || ALL VITALS MODAL */}
            <Modal
              title={[
                <div className="mb-2">
                  <h6 className="float-left">Vitals</h6>
                  {this.state.vitalsFiltersLoaded ? (
                    <button
                      className="btn_filter"
                      onClick={async (e) => {
                        //await this.handleModalPopup(e, "vitalViewModal");
                        await this.handleModalPopup(e, "chartParamerModelVital");
                      }}
                    ></button>
                  ) : null}
                  <div className="float-right mr-3 ml-auto d-flex align-content-center">
                    <Badge className="mr-2 vr-badge" color={"#ffe6e8"} text={"Vitals Entered by Patient"} />
                  </div>
                </div>
              ]}
              visible={this.state.vitalViewModal}
              onCancel={(e) => this.handleModalPopup(e, "vitalViewModal")}
              style={{ top: 40 }}
              width="1080px"
              bodyStyle={{ paddingBottom: "10px", maxHeight: "650px", overflow: "scroll" }}
              footer=''
            >
              <SubHeaderMain />
              <div className="row mt-3">
                <div className="col-12 mb-2">
                  <div className="card rounded-0 cardbg">
                    {this.state.vitalsFiltersLoaded && this.state.vitals_data_formatted ? (
                      <VitalsSS
                        dataLoaded={this.state.vitals_data_formatted}
                        vitalsOrder={this.state.vitals}
                        constants={this.state.constants}
                        visitVitalsFormatted={this.state.visitVitalsFormatted}
                        handleModalPopupStateLift={this.handleModalPopup}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </Modal>

            {/* || ALL KEY FINDINGS MODAL */}
            <Modal
              title={[
                <div className="mb-2">
                  <h6 className="float-left">Key Findings</h6>
                  {this.state.keyFindingsFiltersLoaded ? (
                    <button
                      className="btn_filter"
                      onClick={async (e) => {
                        //await this.handleModalPopup(e, "vitalViewModal");
                        await this.handleModalPopup(e, "chartParamerModel");
                      }}
                    ></button>
                  ) : null}
                  <div className="float-right mr-3 ml-auto d-flex align-content-center">
                    <Badge className="mr-2 vr-badge" color={"#ffe6e8"} text={"Entered by Patient"} />
                  </div>
                </div>
              ]}
              visible={this.state.keyFindingViewModal}
              onCancel={(e) => this.handleModalPopup(e, "keyFindingViewModal")}
              style={{ top: 40 }}
              width="1200px"
              bodyStyle={{ paddingBottom: "10px", maxHeight: "650px", overflow: "scroll" }}
              footer=''
            >
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="card rounded-0 cardbg">
                    {this.state.keyFindingsFiltersLoaded && this.state.keyfindings_data_formatted ? (
                      <KeyFindings
                        dataLoaded={this.state.keyfindings_data_formatted}
                        keyFindingsOrder={this.state.keyFindings}
                        constants={this.state.constants}
                        visitKeyFindingsFormatted={this.state.visitKeyFindingsFormatted}
                        handleModalPopupStateLift={this.handleModalPopup}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </Modal>

            {/* || ALL NEW KEY FINDINGS MODAL */}
            <Modal
              title={[
                <div className="mb-2">
                  <h6 className="float-lefts">Key Findings</h6>
                </div>
              ]}
              
              visible={this.state.keyFindingsNewViewModal}
              onCancel={(e) => this.handleCancelModalChartPopup(e, "keyFindingsNewViewModal")}
              style={{ top: 40 }}
              width="1700px"
              bodyStyle={{ paddingBottom: "10px", maxHeight: "900px", paddingTop: '10px' }}
              footer=''
            >
               <SubHeaderMain />
              <div className="row mt-3">
                <div
                  className="col-12 d-flex justify-content-center mb-4" id="queue-top-navigation-bar"
                >
                  <ul className="chart_filter">
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-day ${this.state.keyFindingsChartsTab === "0" ? "active" : ""}`}
                        onClick={() => this.handleClickDuration('day', '0')}
                      >
                        DAY
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-week ${this.state.keyFindingsChartsTab === "1" ? "active" : ""}`}
                        onClick={() => this.handleClickDuration('week', '1')}
                      >
                        WEEK
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-month ${this.state.keyFindingsChartsTab === "2" ? "active" : ""}`}
                        onClick={() => this.handleClickDuration('month', '2')}
                      >
                        MONTH
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-year ${this.state.keyFindingsChartsTab === "3" ? "active" : ""}`}
                        onClick={() => this.handleClickDuration('year', '3')}
                      >
                        YEAR
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="col-12 mb-2">
                  {

                    <Card
                      bodyStyle={{ padding: "0px" }}
                      style={{padding:"0px"}}
                      loading={!this.state.isKeyFindingsLoaded}
                    >
                      <div className="card rounded-0 cardbg">
                        <KeyFindingsNew
                          keyFindingsNewDataFormatted={this.state.keyFindingsNewDataFormatted}
                          selectedKeyFindingGraph={selected_key_findings}
                          handleModalPopupStateLift={this.handleModalChartPopup}
                          handleKFDataCopyToTemplate={this.handleKFDataCopyToTemplate}
                          kfDataToCopyObj={{}}
                          pageFrom='KeyFindings'                      
                        />
                      </div>
                   </Card>
                  }
                </div>
              </div>
            </Modal>

            {/* || FILTERS & SEQUENCING - Vitals */}
            {this.state.vitalsFiltersLoaded ? (
              <Modal
                title={"Vitals - Parameters"}
                destroyOnClose={true}
                visible={this.state.chartParamerModelVital}
                onCancel={async (e) => {
                  this.handleModalPopup(e, "chartParamerModelVital");
                }}
                style={{ top: "40px" }}
                width="910px"
                footer={false}
                closable={true}
              >
                {this.state.constants && (
                  <VitalsFilters
                    vitalsConstants={this.state.constants.vitals_details}
                    handleModalPopup={this.handleModalPopup}
                    updateParentState={this.handleVitalsUpdate}
                  />
                )}
              </Modal>
            ) : null}

            {/* || FILTERS & SEQUENCING - Key Findings */}
            {this.state.keyFindingsFiltersLoaded ? (
              <Modal
                title={"Key Findings - Parameters"}
                destroyOnClose={true}
                visible={this.state.chartParamerModel}
                onCancel={async (e) => {
                  this.handleModalPopup(e, "chartParamerModel");
                }}
                style={{ top: "40px" }}
                width="910px"
                footer={false}
                closable={true}
              >
                {this.state.constants && (
                  <KeyFindingsFilters
                    keyFindingsConstants={this.state.constants.findingGroupData}
                    handleModalPopup={this.handleModalPopup}
                    updateParentState={this.handleKeyFindingsUpdate}
                  />
                )}
              </Modal>
            ) : null}

            {/* || KEY FINDINGS MODAL */}
            <Modal
              title={[
                <div className="row">
                  <div className="col-3 d-flex align-self-center">
                    Add Key Findings
                  </div>
                  {this.state.keyFindingsFiltersLoaded ?
                    <div className="col-3">
                      <div className={`form-group mb-0 ${this.state.key_finding_date_time ? "hasdata" : ""}`}>
                        <DatePicker
                          id="key_finding_date_time"
                          name="key_finding_date_time"
                          format="MMM, DD YYYY hh:mm A"
                          disabledDate={this.disabledDate}
                          showNow={false}
                          placeholder={""}
                          allowClear
                          defaultPickerValue={this.state.key_finding_date_time}
                          showTime={{ defaultValue: moment() }}
                          defaultValue={this.state.key_finding_date_time}
                          value={this.state.key_finding_date_time}
                          className="form-control datetimepicker"
                          onOk={this.setDateTimeHandler}
                        />
                        <label htmlFor="key_finding_date_time">Select Date Time</label>
                      </div>
                    </div>
                    : null}
                </div>
              ]}
              visible={this.state.keyFindingsModal}
              onCancel={(e) => this.handleModalPopup(e, "keyFindingsModal")}
              style={{ top: 40 }}
              footer={[
                <div className="text-center">
                  <Button
                    type="outline-secondary"
                    onClick={this.handleKeyFindingModalClear}
                    htmlType="button"
                    className="btn btn-outline-secondary cursor-pointer"
                  >
                    CLEAR
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn btn-primary text-uppercase ml-2"
                    onClick={this.handleKeyFindingFormSubmit}
                    disabled={this.handleKeyFindingModalButtonDisabled() || this.state.spin_loading || !this.state.keyFindingsFiltersLoaded}
                  >
                    CONFIRM{this.state.spin_loading ? LOADER : ""}
                  </Button>
                </div>,
              ]}
              width="1080px"
              bodyStyle={{ paddingBottom: "10px", maxHeight: "650px", overflow: "scroll", paddingTop: '5px' }}
              className="emrfrm"
            >
              {/* New Form Added in HTML */}
              {this.state.keyFindingsFiltersLoaded ? this.getKeyFindingFFormItem() : null}
              {/* New Form Added in HTML End */}
            </Modal>

            {/* || CHART MODAL */}
            {this.state.chartModal && this.state.vitalsFiltersLoaded ? (
              <Modal
                title={this.state.chartHeading}
                visible={this.state.chartModal}
                onCancel={(e) => this.handleModalPopup(e, "chartModal")}
                style={{
                  top: "40px",
                  maxWidth: this.state.chartsType === "all" ? "90%" : "600px",
                }}
                width="100%"
                className={`${this.state.chartsType === "all" ? "all_vital" : "single_vital"}`}
                bodyStyle={{ maxHeight: "700px", overflowY: "auto", overflowX: "hidden" }}
                footer={
                  this.state.chartsType === "all" ? (
                    <div className="text-center justify-content-center">
                      <button type="submit" className="btn btn-primary text-uppercase ml-2" onClick={(e) => this.handleModalPopup(e, "chartModal")}>
                        Confirm
                      </button>
                    </div>
                  ) : null
                }
              >
                <div
                  className={`col wrap_chart_filter d-flex justify-content-around align-items-center ${this.state.chartsType !== "all" ? "wrap_chart_filter_small" : ""
                    }`}
                >
                  <ul className="chart_filter">
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-day ${this.state.chartsTab === "0" ? "active" : ""}`}
                        onClick={(e) => this.handleGraphClick(e, "0")}
                      >
                        <span>DAY</span>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-week ${this.state.chartsTab === "1" ? "active" : ""}`}
                        onClick={(e) => this.handleGraphClick(e, "1")}
                      >
                        <span>  WEEK</span>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-month ${this.state.chartsTab === "2" ? "active" : ""}`}
                        onClick={(e) => this.handleGraphClick(e, "2")}
                      >
                        <span>  MONTH </span>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-year ${this.state.chartsTab === "3" ? "active" : ""}`}
                        onClick={(e) => this.handleGraphClick(e, "3")}
                      >
                        <span>  YEAR </span>
                      </button>
                    </li>
                  </ul>

                  {this.state.chartsType === "all" && (
                    <RangePicker
                      allowClear={true}
                      value={this.state.range}
                      onChange={this.handleRangeChange}
                      disabledDate={DISABLED_FUTURE_DATES}
                      format="DD/MM/YYYY"
                    />
                  )}
                </div>

                {this.state.chartDataLoaded ? (
                  <Chart
                    chartData={this.state.chartData}
                    bloodSugarChartData={this.state.bloodSugarChartData}
                    vitalsRangeWithId={this.state.vitalsRangeWithId}
                    dataLoaded={this.state.chartDataLoaded}
                    chartsType={this.state.chartsType}
                    reference_id={this.state.patient.qms_token_id}
                    chartGraphSetting={this.state.chartGraphSettings}
                    chartsTab={this.state.chartsTab}
                    constants={this.state.constants}
                    updateData={this.chartStateUpdate}
                    checkBoxDisable={!this.state.allowEdit}
                  />
                ) : null}
              </Modal>
            ) : null}

              {/* || REASON MODAL */}
              <Modal
                title={`Reason for Change`}
                visible={this.state.reasonModal}
                onCancel={(e) => this.handleModalPopup(e, 'reasonModal')}
                centered
                footer={[
                  <div className="text-center">
                    <Button type="secondary" className="btn btn-secondary text-uppercase"
                      onClick={e => this.handleModalPopup(e, 'reasonModal')}>
                      CANCEL
                    </Button>
                    <Button form="reasonForm" type="primary" htmlType="submit" className="btn btn-primary text-uppercase ml-2"
                      disabled={this.state.spin_loading}>
                      SAVE{this.state.spin_loading ? LOADER : ''}
                    </Button>
                  </div>
                ]}
                className="emrfrm"
              >
                 <Form
                    id="reasonForm"
                    ref={this.reasonFormRef}
                    validateMessages={HANDLEVALIDANTMESSAGES}
                    onFinish={this.handleReasonFormSubmit}
                    layout="vertical"
                  >

                    <Radio.Group
                      className="d-flex flex-column"
                      onChange={this.handleAntRadioChange}
                      value={this.state.reasonChangeValue}>

                      {this.reasonChange.map((rc, i) =>
                        <Radio value={rc.value} className="mb-2" key={rc.value}>
                          {rc.reason}
                        </Radio>
                      )}

                    </Radio.Group>

                    {this.state.reasonChangeValue === 2 && (
                      <Form.Item name="reason"
                        rules={[{
                          required: true,
                        }]}>
                        <TextArea placeholder="Enter Reason" className="w-100 mt-2" rows={3} />
                      </Form.Item>
                    )}

                  </Form>

              </Modal>
              {/* || REASON MODAL */}

            {/* || KEY FINDINGS CHART MODAL */}
            {this.state.keyFindingChartModal && this.state.keyFindingsFiltersLoaded ? (
              <Modal
                destroyOnClose={true}
                // title={`${this.state.chartsType === 'all' ? 'Vital' : this.state.chartsType} Graphs`}
                title={this.state.keyFindingsChartHeading}
                visible={this.state.keyFindingChartModal}
                onCancel={(e) => this.handleModalPopup(e, "keyFindingChartModal")}
                style={{
                  top: "40px",
                  maxWidth: this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group" ? "90%" : "600px",
                }}
                width="100%"
                className={`${this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group" ? "all_vital" : "single_vital"
                  }`}
                bodyStyle={{ maxHeight: "700px", overflowY: "auto" }}
                footer={
                  this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group" ? (
                    <div className="text-center justify-content-center">
                      <button
                        type="submit"
                        className="btn btn-primary text-uppercase ml-2"
                        onClick={(e) => this.handleModalPopup(e, "keyFindingChartModal")}
                      >
                        Confirm
                      </button>
                    </div>
                  ) : null
                }
              >
                <div
                  className={`col wrap_chart_filter d-flex justify-content-around align-items-center ${this.state.keyFindingsChartsType !== "all" && this.state.keyFindingsChartsType !== "finding_group" ? "wrap_chart_filter_small" : ""
                    }`}
                >
                  <ul className="chart_filter">
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-day ${this.state.keyFindingsChartsTab === "0" ? "active" : ""}`}
                        onClick={(e) => this.handleKeyGraphClick(e, "0")}
                      >
                        DAY
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-week ${this.state.keyFindingsChartsTab === "1" ? "active" : ""}`}
                        onClick={(e) => this.handleKeyGraphClick(e, "1")}
                      >
                        WEEK
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-month ${this.state.keyFindingsChartsTab === "2" ? "active" : ""}`}
                        onClick={(e) => this.handleKeyGraphClick(e, "2")}
                      >
                        MONTH
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className={`btn btn-default chart-year ${this.state.keyFindingsChartsTab === "3" ? "active" : ""}`}
                        onClick={(e) => this.handleKeyGraphClick(e, "3")}
                      >
                        YEAR
                      </button>
                    </li>
                  </ul>

                  {(this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group") && (
                    <RangePicker
                      allowClear={true}
                      value={this.state.key_findings_range}
                      onChange={this.handleKeyFindingsRangeChange}
                      disabledDate={DISABLED_FUTURE_DATES}
                      format="DD/MM/YYYY"
                    />
                  )}
                </div>

                {this.state.keyFindingsChartDataLoaded ? (
                  <KeyFindingsChart
                    chartData={this.state.keyFindingsChartData}
                    dataLoaded={this.state.keyFindingsChartDataLoaded}
                    keyFindingsOrder={this.state.keyFindings}
                    chartsType={this.state.keyFindingsChartsType}
                    chartsId={this.state.keyFindingsChartId}
                    reference_id={this.state.patient.qms_token_id}
                    chartGraphSetting={this.state.keyFindingsChartGraphSettings}
                    chartsTab={this.state.keyFindingsChartsTab}
                    constants={this.state.constants}
                    updateData={this.chartStateUpdateKeyFindigs}
                    checkBoxDisable={!this.state.allowEdit}
                  />
                ) : null}

              </Modal>
            ) : null}

            {/* || KEY FINDINGS CHART MODAL New */}
            {this.state.keyFindingChartModalNew ? (
              <Modal
                destroyOnClose={true}
                // title={`${this.state.chartsType === 'all' ? 'Vital' : this.state.chartsType} Graphs`}
                title={this.state.keyFindingsChartHeading}
                visible={this.state.keyFindingChartModalNew}
                onCancel={(e) => this.handleModalPopup(e, "keyFindingChartModalNew")}
                style={{
                  top: "40px",
                  maxWidth: this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding-group" ? "90%" : "600px",
                }}
                className={`${this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding-group" ? "all_vital" : "single_vital"
                  }`}
                width="100%"
                bodyStyle={{ maxHeight: "650px", overflowY: "auto" }}
                footer={
                  this.state.keyFindingsChartsType === "all" || this.state.keyFindingsChartsType === "finding_group" ? (
                    <div className="text-center justify-content-center">
                      <button
                        type="submit"
                        className="btn btn-primary text-uppercase ml-2"
                        onClick={(e) => this.handleModalChartPopup(e, "keyFindingChartModalNew")}
                      >
                        Confirm
                      </button>
                    </div>
                  ) : null
                }
              >
                <KeyFindingsChartNew
                  data={this.state.kfChartData}
                  chartType={this.state.keyFindingsChartsType}
                  chartHeading={this.state.keyFindingsChartHeading}
                  showCheckBox={true}
                  selectedKeyFindingGraph={this.state.selected_key_findings}
                  selectKeyFindingGraph={this.selectKeyFindingGraph}
                />
              </Modal>
            ) : null}
          </>
        ) : (
          <div className="w-100 align-items-center justify-content-center mt-4 d-flex">{LOADER_RED}</div>
        )}
      </div>

    )
  }
}

export default VitalsKeyFindings;