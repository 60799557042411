import React from 'react';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Moment from "react-moment";
import { LOADER_RED, LOADER, KEY_FINDINGS_LIS_STORE_NOTES } from '../../utils/constant';
import LS_SERVICE from '../../utils/localStorage';
import { Popover, Modal, Button, Form, Input, notification } from 'antd';
import {
  SettingFilled, FileOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRadiationAlt } from '@fortawesome/free-solid-svg-icons'
import { string } from 'prop-types';
import Axios from 'axios';
import { asyncScheduler } from 'rxjs';
import { Line } from 'react-chartjs-2';


class KeyFindingsNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '', 
      selectedKeyFindingGraph: props.selectedKeyFindingGraph ?? {},
      keyFindingsNewDataFormatted: props.keyFindingsNewDataFormatted ?? {},
      keyfindingDates: props?.keyFindingsNewDataFormatted?.kfdates ?? [],
      keyfindingDepartments: props?.keyFindingsNewDataFormatted?.departmentTypes ?? [],
      keyfindingDatesFormatted: props?.keyFindingsNewDataFormatted?.kfdates?.slice(0, 5) ?? [],
      chartDataGroup: props.keyFindingsNewDataFormatted?.chartDataGroup ?? {},
      kfDisplayData: props.keyFindingsNewDataFormatted?.kfDisplayData ?? {},
      kfDisplayDataNonNumeric: props.keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric ?? {},
      kfDisplayDataInv: props.keyFindingsNewDataFormatted?.kfDisplayDataInv ?? {},
      path: null,
      showViewerModal: false,
      impression_notes: '',
      showNotesModal: false,
      sample_id: null,
      notesSaved: false,
      index: 0,
      selectedKeysArr: [],
      copyToDSArr: [],
      copyToDSFormattedArr: {}
    }

    this.notesRef = React.createRef();
  }

  async componentDidMount() {
    let kfDataToCopyObj = this.props.kfDataToCopyObj;    
    if(Object.keys(kfDataToCopyObj).length) {
      let resultArray = [];
      Object.keys(kfDataToCopyObj).forEach(key => {
        resultArray = resultArray.concat(kfDataToCopyObj[key]);
      });

      let selectedKeysArr = [];
      Object.keys(kfDataToCopyObj).forEach(item => {
        let keySplit = item.split(' | ')[1];
        selectedKeysArr.push(keySplit);
      });

      this.setState({copyToDSArr: resultArray, copyToDSFormattedArr: kfDataToCopyObj, selectedKeysArr});
    }
  }

  onViewClick(path) {
    this.setState({
      path: path,
      showViewerModal: true
    });
  }

  handleNotesPopup(sample_id, impression_notes) {
    this.setState({ sample_id: sample_id, impression_notes: impression_notes, showNotesModal: true, });
  }

  onSaveNotes(values, sample_id) {

    // let Url = MHEA_API_BASE_URL + "API/ServiceOrder/UpdateImpression";

    let data = {
      lis_sampledata_id: sample_id,
      impression: values.notes,
      enteredById: LS_SERVICE.get('staff_id')
    }

    this.setState({ notesSaved: true, impression_notes: values.notes });

    Axios.post(KEY_FINDINGS_LIS_STORE_NOTES, data)
      .then(res => {
        let response = res.data.data;
        if (response.statusCode == 200) {
          this.setState({ showNotesModal: false, notesSaved: false });
          notification.success({
            message: "Notes saved successfully!",
            placement: 'topRight'
          })
          // this.notesRef.current.setFieldsValue({
          //   notes: null
          // });
        }
      }).catch(err => {
        this.setState({ showNotesModal: false, notesSaved: false });
        // this.notesRef.current.setFieldsValue({
        //   notes: null
        // });
        notification.error({
          message: "Something Went Wrong!",
          placement: 'topRight'
        })
        console.log("Error", err);
      })
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    if (prevState.keyFindingsNewDataFormatted !== nextProps.keyFindingsNewDataFormatted) {
      return {
        keyFindingsNewDataFormatted: nextProps?.keyFindingsNewDataFormatted,
        keyfindingDatesFormatted: nextProps?.keyFindingsNewDataFormatted?.kfdates.slice(0, 5),
        chartDataGroup: nextProps?.keyFindingsNewDataFormatted?.chartDataGroup ?? {},
        kfDisplayData: nextProps?.keyFindingsNewDataFormatted?.kfDisplayData ?? {},
        kfDisplayDataNonNumeric: nextProps?.keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric ?? {},
        kfDisplayDataInv: nextProps?.keyFindingsNewDataFormatted?.kfDisplayDataInv ?? {},
      };
    }
    return null;
  }

  onClickArrow = async (arrow_type) => {
    const { keyfindingDates, keyfindingDatesFormatted } = this.state;
    var size = 5;
    if (arrow_type == 'next') {
      await this.setState({ index: this.state.index + size });
      while (this.state.index >= keyfindingDates?.length) {
        this.setState({ index: this.state.index - size });
      };
      let nextDates = keyfindingDates.slice(this.state.index, this.state.index + size);
      this.setState({ keyfindingDatesFormatted: nextDates });
    }

    if (arrow_type == 'previous') {
      await this.setState({ index: this.state.index - size });
      while (this.state.index < 0) {
        this.setState({ index: this.state.index + size });
      };
      let prevDates = keyfindingDates.slice(this.state.index, this.state.index + size);
      this.setState({ keyfindingDatesFormatted: prevDates });
    }
  }

  GET_RANGE_CLASS = (status) => {
    if (status == "-") return "";
    let vitalRange = "";
    //normal range
    if (status == 'N')
      vitalRange = "text-success";

    //abnormal range
    if (
      status == 'P'
    )
      vitalRange = "text-danger";

    //critical range
    if (
      status == 'A' || status == 'L' || status == 'H'
    )
      vitalRange = "text-warning";

    return vitalRange;
  };

  handleColumnCheckbox(e, date, department) {
    let { copyToDSArr } = this.state;

    if (e.target.checked) {
      let { keyFindingsNewDataFormatted, kfDisplayData } = this.state;
      let headings = typeof keyFindingsNewDataFormatted?.kfDisplayData != 'undefined' ? Object.keys(keyFindingsNewDataFormatted?.kfDisplayData) : [];
      let nonNumericHeadings = typeof keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric != 'undefined' ? Object.keys(keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric) : [];
      let invHeadings = typeof keyFindingsNewDataFormatted?.kfDisplayDataInv != 'undefined' ? Object.keys(keyFindingsNewDataFormatted?.kfDisplayDataInv) : [];

      headings?.length && headings.forEach((kf, k2) => {
        let subHeadings = Object.keys(kfDisplayData[kf]);
        subHeadings.length && subHeadings.forEach(sh => {
          let testValue = kfDisplayData[kf][sh][date]?.test_value;
          if (testValue) {
            let isNormal = kfDisplayData[kf][sh][date]?.isNormal;
            let kfObj = {
              date, department, testValue,
              heading: kf,
              subHeading: sh,
              isNormal: this.GET_RANGE_CLASS(isNormal),
              UOM: kfDisplayData[kf][sh]?.UOM,
              referenceRange: kfDisplayData[kf][sh]?.reference_range,
              selected: false,
            };
            copyToDSArr.push(kfObj);
          }
        })
      })
    }
    else {
      var copyToDSArr1 = copyToDSArr; // initilize
      copyToDSArr = copyToDSArr1.filter(item => item.date != date);
    }

    // Group by "heading | department - date"
    const copyToDSFormattedArr = copyToDSArr.reduce((acc, item) => {
      const key = `${item.heading} | ${item.department} - ${item.date}`;
      // Initialize the array for the current key if it doesn't exist
      if (!acc[key]) { acc[key] = []; }
      // Add the current item to the array for the key
      acc[key].push(item);
      return acc;
    }, {});

    let selectedKeysArr = [];
    Object.keys(copyToDSFormattedArr).forEach(item => {
      let keySplit = item.split(' | ')[1];
      selectedKeysArr.push(keySplit);
    });

    this.setState({ copyToDSArr, copyToDSFormattedArr, selectedKeysArr });
  }

  handleCheckAll(heading) {
    this.setState((prevState) => {
      const updatedTestResults = { ...prevState.copyToDSFormattedArr };
      const selectedItem = updatedTestResults[heading];
      for (let index = 0; index < selectedItem.length; index++) {
        // Toggle the 'selected' property of the item
        selectedItem[index].selected = !selectedItem[index].selected;        
      } 
      return { copyToDSFormattedArr: updatedTestResults };
    });
  }  

  handleCheck(heading, index) {
    this.setState((prevState) => {
      const updatedTestResults = { ...prevState.copyToDSFormattedArr };
      const selectedItem = updatedTestResults[heading][index];
      
      // Toggle the 'selected' property of the item
      selectedItem.selected = !selectedItem.selected;
  
      return { copyToDSFormattedArr: updatedTestResults };
    });
  }  

  submitKeyFinding(type) {
    if(type==="keyFinding"){
      let {copyToDSFormattedArr} = this.state;
      let insertNoneHtml = "";
      let appenedKFtHtml = "";
  
      // CHECK ANY SELECT OR NOT
      let hasSelectedTests = Object.keys(copyToDSFormattedArr).some(dateKey => copyToDSFormattedArr[dateKey].some(test => test.selected === true));
      if(!hasSelectedTests) {
        notification.destroy();
        notification.error({
          message: "Select any key finding value to show in print",
          placement: 'topRight'
        });
        return;
      }
  
      Object.keys(copyToDSFormattedArr).map((kfhData, kfhKey) => {
        let splitHedaing = kfhData.split(' | ')[0];
        let splitDate = kfhData.split(' - ')[1];
        let className = splitHedaing.replace(/\s/g, "_");
        let kfText = "";
  
        let hasSelectedTests = copyToDSFormattedArr[kfhData].some(test => test.selected === true);
        if(hasSelectedTests) {
          copyToDSFormattedArr[kfhData].forEach(kfshObj => {
            if(kfshObj.selected) {
              kfText += `${kfshObj.subHeading}: ${kfshObj.testValue} ${kfshObj.UOM}, `;
            }
          });
          
          kfText = kfText.replace(/,\s*$/, "");
          appenedKFtHtml += '<div key="' + kfhKey + '" class="' + className + '"><b>' + splitHedaing + '&nbsp; (' + splitDate + ') :</b>&nbsp;' + kfText + insertNoneHtml + '</div>';
        }
      });
  
      this.props.handleKFDataCopyToTemplate(copyToDSFormattedArr, appenedKFtHtml, null);
    }else if(type==="keyGraph"){
      this.props.handleKFDataCopyToTemplate(null, null, this.props.selectedKeyFindingGraph);
    }
  }
  // Function to handle search input change
  handleSearchChange = (e) => {
    this.setState({ searchQuery: e.target.value });
  };

  // Function to filter data based on the search query
  filterKeyFindings = (kfDisplayData, kf) => {
    const { searchQuery } = this.state;
    const data = kfDisplayData[kf];
  
    if (!searchQuery) return data; // Return unfiltered data if no search query is provided
  
    // Prepare a new object to store the filtered results
    const filteredData = {};
  
    // Iterate over the object properties (dates in this case)
    Object.keys(data).forEach((key) => {
      const entry = data[key];
  
      // Check if the key (date) or any of the values (test_value, reference_range, etc.) matches the search query
      if (
        key.toLowerCase().includes(searchQuery.toLowerCase()) || // Search by date
        (entry?.test_value && entry.test_value.toLowerCase().includes(searchQuery.toLowerCase())) || // Search by test_value
        (entry?.reference_range && entry.reference_range.toLowerCase().includes(searchQuery.toLowerCase())) // Search by reference_range
      ) {
        filteredData[key] = entry; // Add matching entry to the filtered data
      }
    });
  
    return filteredData;
  };
  

  render() {
    const selectedKeyFindingGraph = this.props.selectedKeyFindingGraph;
    const { keyfindingDates, keyfindingDatesFormatted, chartDataGroup, kfDisplayData, kfDisplayDataNonNumeric, kfDisplayDataInv, copyToDSArr, copyToDSFormattedArr, selectedKeysArr } = this.state;
    let headings = typeof this.state.keyFindingsNewDataFormatted?.kfDisplayData != 'undefined' ? Object.keys(this.state.keyFindingsNewDataFormatted?.kfDisplayData) : [];
    let nonNumericHeadings = typeof this.state.keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric != 'undefined' ? Object.keys(this.state.keyFindingsNewDataFormatted?.kfDisplayDataNonNumeric) : [];
    let invHeadings = typeof this.state.keyFindingsNewDataFormatted?.kfDisplayDataInv != 'undefined' ? Object.keys(this.state.keyFindingsNewDataFormatted?.kfDisplayDataInv) : [];
    console.log("this.props.selectedKeyFindingGraph=======>",selectedKeyFindingGraph);
    return (
      <>
        <div className="card-body vitals">
          <div className="row">
            <div
              className={`table-responsive ${Object.keys(selectedKeyFindingGraph).length && copyToDSArr.length ? 'col-4' : Object.keys(selectedKeyFindingGraph).length || copyToDSArr.length ? 'col-8':'col-12'}`}
              style={{ padding: "0px" }}
            >
              <div className='fixed-table'>
                <div className="antInputItem m-3">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="form-control"
                      value={this.state.searchQuery}
                      onChange={this.handleSearchChange}
                    />
                  </div>
                </div>
                <table className="table">
                  <tbody>
                    {typeof keyfindingDatesFormatted != 'undefined' && keyfindingDatesFormatted?.length > 0 ?
                      <tr>
                        <th width="20%">Parameter
                          <span disabled="disabled" className="" style={{ position: 'absolute', right: '3px', top: "26px" }}>
                            <a >
                              <span disabled className="float-right chevron chevron-left arrow-left"
                                onClick={() => this.onClickArrow('previous')}
                              />
                            </a>
                          </span>
                        </th>
                        {
                          keyfindingDatesFormatted.map((v, i) => (
                            <th key={i} className={`pl-0 pr-0`} style={{ maxWidth: "250px" }}>
                              <div className="row">
                                <div className="col-12 text-center vital-time">
                                  <div>
                                    {this.props.pageFrom == 'KeyFindings' ? <>
                                      <input type='checkbox' style={{ cursor: 'pointer' }} 
                                      checked={selectedKeysArr.includes(`${this.state.keyfindingDepartments[i]} - ${v}`)} 
                                      onClick={(e) => this.handleColumnCheckbox(e, v, this.state.keyfindingDepartments[i])} /> &nbsp;
                                    </> : null}
                                    {this.state.keyfindingDepartments[i]}
                                  </div>
                                  {v}
                                </div>
                              </div>
                            </th>
                          ))
                        }
                        <th className="text-center text-bold" style={{ width: '132px' }}>
                          Reference Range
                          <span disabled="disabled" className="" style={{ position: 'absolute', left: '-7px', top: "26px" }}>
                            <a >
                              <span disabled={keyfindingDatesFormatted?.length > 5 ? true : false} className="float-right chevron chevron-right arrow-right"
                                onClick={() => this.onClickArrow('next')}
                              />
                            </a>
                          </span>
                        </th>
                        <th className="text-center text-bold">Trend Graph</th>
                      </tr>
                      : <tr><td className="pt-2">No Record Found</td></tr>}

                    {/* Numeric GRP*/}
                    {
                      typeof keyfindingDatesFormatted != 'undefined' && keyfindingDatesFormatted?.length > 0 ?
                        typeof headings != 'undefined' && headings?.length > 0 ?
                          headings.map((kf, k2) => {
                            let kfDisplayDatanew =  this.filterKeyFindings(kfDisplayData, kf);
                            let subHeadings = Object.keys(kfDisplayDatanew);
                            let testData = this.state.chartDataGroup[kf];
                            return (
                              <React.Fragment key={k2}>
                                <tr>
                                  <td className={`text-bold`}>{kf}</td>
                                  {
                                    keyfindingDatesFormatted.map(date => {
                                      return (<td></td>)
                                    })
                                  }
                                  <td></td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn chart-btn button-chart-individual"
                                      onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModalNew', { type: 'finding-group', name: 'Key Findings Trend Graphs', testData, testName: kf })}
                                    >
                                      <i className="icon-chart"></i>
                                    </button>
                                  </td>
                                </tr>
                                {
                                  typeof subHeadings != 'undefined' && subHeadings?.length > 0 ?
                                    subHeadings.map(sh => {
                                      let refDate;
                                      let isNormal;
                                      let testName;
                                      let testDataIndividual;
                                      return (
                                        <>
                                          <tr>
                                            <td>{sh}</td>
                                            {
                                              keyfindingDatesFormatted.map(date => {
                                                refDate = date;
                                                isNormal = kfDisplayData[kf][sh][date]?.isNormal;
                                                testName = sh;
                                                testDataIndividual = this.state.chartDataGroup[kf];
                                                if (typeof kfDisplayData[kf][sh][date]?.test_value != undefined && kfDisplayData[kf][sh][date]?.test_value != "") {
                                                  return (
                                                    <td style={{ maxWidth: '114px', width: '114px' }} className={`text-center ${this.GET_RANGE_CLASS(isNormal)}`}>{kfDisplayData[kf][sh][date]?.test_value != null && kfDisplayData[kf][sh][date]?.test_value != "" ? kfDisplayData[kf][sh][date]?.test_value : '-'}</td>
                                                    // <td className='text-center'>-</td>
                                                  )
                                                } else {
                                                  return (
                                                    <td className='text-center'>-</td>
                                                  )
                                                }
                                              })
                                            }
                                            <td className='text-center'><div dangerouslySetInnerHTML={{ __html: kfDisplayData[kf][sh]?.reference_range }} />{kfDisplayData[kf][sh]?.UOM != "" ? `( ${kfDisplayData[kf][sh]?.UOM} )` : ""}</td>
                                            <td>
                                              <button
                                                type="button"
                                                className="btn chart-btn button-chart-individual"
                                                onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModalNew', { type: 'individual', id: 1, name: 'Key Findings Trend Graphs', isNormal, testDataIndividual, testName })}
                                              >
                                                <i className="icon-chart"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    })
                                    : null
                                }
                              </React.Fragment>
                            );
                          })
                          : null
                        : null
                    }

                    {/* Numeric INV*/}
                    {
                      typeof keyfindingDatesFormatted != 'undefined' && keyfindingDatesFormatted?.length > 0 ?
                        typeof invHeadings != 'undefined' && invHeadings?.length > 0 ?
                          invHeadings.map((ikf, k2) => {
                            let refDate;
                            let isNormal;
                            let testName;
                            let testDataIndividual = this.state.chartDataGroup[ikf];
                            let testObj = {};
                            testObj[ikf] = testDataIndividual;
                            return (
                              <React.Fragment key={k2}>
                                {
                                  <tr>
                                    <td className={`text-bold`}>{ikf}</td>
                                    {
                                      keyfindingDatesFormatted.map(date => {
                                        refDate = date;
                                        isNormal = kfDisplayDataInv[ikf][date]?.isNormal;
                                        testName = ikf;

                                        if (typeof kfDisplayDataInv[ikf][date]?.test_value != undefined && kfDisplayDataInv[ikf][date]?.test_value != "") {
                                          return (
                                            <td style={{ maxWidth: '114px', width: '114px' }} className={`text-center ${this.GET_RANGE_CLASS(isNormal)}`}>{kfDisplayDataInv[ikf][date]?.test_value != null && kfDisplayDataInv[ikf][date]?.test_value != "" ? kfDisplayDataInv[ikf][date]?.test_value : '-'}</td>
                                          )
                                        } else {
                                          return (
                                            <td className='text-center'>-</td>
                                          )
                                        }
                                      })
                                    }
                                    <td className='text-center'><div dangerouslySetInnerHTML={{ __html: kfDisplayDataInv[ikf]?.reference_range }} />{kfDisplayDataInv[ikf]?.UOM != "" && kfDisplayDataInv[ikf]?.UOM != null ? `( ${kfDisplayDataInv[ikf]?.UOM} )` : ""}</td>
                                    <td>
                                      <button
                                        type="button"
                                        className="btn chart-btn button-chart-individual"
                                        onClick={e => this.props.handleModalPopupStateLift(e, 'keyFindingChartModalNew', { type: 'individual', id: 1, name: 'Key Findings Trend Graphs', isNormal, testDataIndividual: testObj, testName })}
                                      >
                                        <i className="icon-chart"></i>
                                      </button>
                                    </td>
                                  </tr>

                                }
                              </React.Fragment>
                            );
                          })
                          : null
                        : null
                    }

                    {/* Non Numeric*/}
                    {
                      typeof keyfindingDatesFormatted != 'undefined' && keyfindingDatesFormatted?.length > 0 ?
                        typeof nonNumericHeadings != 'undefined' && nonNumericHeadings?.length > 0 ?
                          nonNumericHeadings.map((kf, k2) => {
                            return (
                              <React.Fragment key={k2}>
                                <tr>
                                  <td className={`text-bold`}>{kf}</td>
                                  {
                                    keyfindingDatesFormatted.sort().reverse().map(date => {
                                      let image = kfDisplayDataNonNumeric[kf][date]?.imageURL
                                      let result = kfDisplayDataNonNumeric[kf][date]?.result_pdf
                                      let lis_sampledata_id = kfDisplayDataNonNumeric[kf][date]?.lis_sampledata_id
                                      let impression_notes = kfDisplayDataNonNumeric[kf][date]?.impression
                                      if (typeof image != undefined || typeof result != undefined) {
                                        return (<td className='text-center non-numeric-td' style={{ maxWidth: '114px', width: '114px' }}>
                                          {
                                            image != "" && image != null ?
                                              <a className='non-numeric-a' style={{ marginRight: '11px', color: 'red', textDecoration: 'underline' }}
                                                onClick={() => this.onViewClick(result)}
                                              ><FontAwesomeIcon icon={faRadiationAlt} className="text-warning cursor-p" style={{ fontSize: '17px' }} /></a>
                                              : null
                                          }

                                          {
                                            result != "" && result != null ?
                                              <a className='non-numeric-a text-success' style={{ marginRight: '11px' }} href={result} target="_blank" rel="noopener noreferrer">Resulted</a>
                                              : null
                                          }

                                          {
                                            (image != "" && image != null) || (result != "" && result != null) ?
                                              <a className='non-numeric-a' title={impression_notes} onClick={() => this.handleNotesPopup(lis_sampledata_id, impression_notes)}><FileOutlined /></a> :
                                              null
                                          }

                                        </td>)
                                      } else {
                                        return (<td>-</td>)
                                      }

                                    })
                                  }
                                </tr>
                              </React.Fragment>
                            );
                          })
                          : null
                        : null
                    }
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-4" style={copyToDSArr.length ? { padding: "0px" } : { display: "none" }}>
              <div className='fixed-table'>
                {copyToDSArr.length && Object.keys(copyToDSFormattedArr).map(kfKey => {
                  return (
                    <>
                      <div className='font-weight-bold text-center row' style={{ paddingTop: "5px",paddingBottom: "5px", }}>
                        <div className='col-1'>
                          <input type='checkbox' onClick={() => this.handleCheckAll(kfKey)} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className='col-11'>
                          <label>{kfKey}</label> 
                        </div>
                      </div>
                    

                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th width="50%">Parameter</th>
                            <th width="15%">Value</th>
                            <th width="25%">Reference Range</th>
                          </tr>
                          {copyToDSFormattedArr[kfKey].map((kfObj, kfIndex) => (
                            <>
                              <tr>
                                <td>
                                  <div onClick={() => this.handleCheck(kfKey, kfIndex)}>
                                    <input type='checkbox' style={{ cursor: 'pointer', marginRight:"10px", }} checked={kfObj.selected} defaultChecked={kfObj.selected} />
                                    <label>{kfObj.subHeading}</label> 
                                  </div>
                                </td>
                                <td className={kfObj.isNormal}>{kfObj.testValue}</td>
                                <td>{kfObj.referenceRange} {kfObj.UOM ? `(${kfObj.UOM})` : null}</td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )
                })}
              </div>

              <div className='row mt-3'>
                <div className="col-12 text-right" >
                  <Button type="primary" htmlType="submit" onClick={()=>this.submitKeyFinding("keyFinding")} className="btn btn-sm btn-primary">ADD TO PRINT</Button>
                </div>
              </div>
            </div>

            <div className="col-4" style={{ padding: "0px" }}>
              <div className="fixed-table">
                {Object.keys(selectedKeyFindingGraph).map(kfKey => {
                  console.log("selectedKeyFindingGraph==kfKey=>", kfKey);
                  const showObj = selectedKeyFindingGraph[kfKey];
                  const showData = showObj[`data_${kfKey}`];
                  const showOptions = showObj[`options_${kfKey}`];

                  return (
                    <React.Fragment key={kfKey}> {/* Always set a key for the fragment in a map */}
                      <div className="font-weight-bold text-center" style={{ lineHeight: "45px" }}>
                        {kfKey}
                      </div>
                      <div className="chart-container"> {/* Replaced table with a div */}
                        <Line
                          data={showData}
                          options={showOptions}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>

              <div className="row mt-3">
                <div className="col-12 text-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => this.submitKeyFinding("keyGraph")}
                    className="btn btn-sm btn-primary"
                  >
                    ADD GRAPH TO PRINT
                  </Button>
                </div>
              </div>
            </div>


          </div>
        </div>  

        {/* For Viewing Material */}
        <Modal
          visible={this.state.showViewerModal}
          footer={false}
          width={850}
          onCancel={() => { this.setState({ showViewerModal: false, pdfPath: null, index: 0 }) }}
          style={{ top: 20 }}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              {this.state.path != null ? <iframe src={this.state.path} frameBorder="0" scrolling="no" style={{ overflow: 'hidden' }} width="100%" height="700">
                {LOADER_RED}
              </iframe> : null}
            </div>
          </div>
        </Modal>

        <Modal
          visible={this.state.showNotesModal}
          footer={false}
          width={600}
          onCancel={() => { this.setState({ showNotesModal: false }) }}
          style={{ top: 20 }}
        >
          <Form ref={this.notesRef} layout='vertical' onFinish={(val) => this.onSaveNotes(val, this.state.sample_id)}>
            <Form.Item label="Notes" type='text' name='notes' className='mt-3' style={{ marginTop: '1rem' }}>
              <Input autoComplete="off" autoFocus={true} value={this.state.impression_notes} defaultValue={this.state.impression_notes} />
            </Form.Item>
            <div className='text-center' style={{ marginTop: '1rem' }}>
              <button disabled={this.state.notesSaved} type='Submit' className="btn btn-primary">
                Save {!this.state.notesSaved ? "" : LOADER}
              </button>
            </div>
          </Form>
        </Modal>
      </>


    );
  }
}

export default KeyFindingsNew;
