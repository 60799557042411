import React, { Component } from 'react';
import Axios from 'axios';
import qs from 'qs';
import $ from 'jquery';
import AsideLeft from '../../components/aside/asideleft';
import LS_SERVICE from '../../utils/localStorage';
//import custom from '../../assets/css/custom.css'
import { Popover, Checkbox } from 'antd';
import {
  PATIENT_DATA, VaccinationListV2, VaccinationAdd, VaccinationItemList,
  VaccinationRecordList, SOAP_CONFIGURATION_LIST,
  VACCINATION_AGE_MESSAGE, COMBINE_VACCINATION_DATA, PAEDRATICAGELIMIT, PAEDRATICVACCINEID, ISALLOWEDITSOAP, USERTYPES, VACCINATION_SITES
}
  from '../../utils/constant';
import { headerService$ } from '../../utils/rxjs-sharing';
import moment from 'moment';
import { Form, Modal, DatePicker, notification, Button } from 'antd';
import SubHeader from '../../components/subheader/subheader';
class VaccinationRecord extends Component {


  constructor(props) {
    super(props)
    this.state = {
      viewChartSection: false,
      printChartModal: false,
      vaccinationData: [],
      giveOn: '',
      batch: '',
      due_date: '',
      givenOutside: '',
      remarks: '',
      record: {},
      chartData: [],
      vaccArr: [],
      vaccinations: [],
      vaccinationsOptional: [],
      itemData: [],
      vaccinationLists: [],
      IAPRecord: false,
      OptionalRecord: false,
      tillCurrent: 0,
      currentAge: 0,
      message: VACCINATION_AGE_MESSAGE,
      birthDate: moment().format("DD/MM/YYYY"),
      current_date: moment(),
      notClickable: false,
      optionalDisplay: 1,
      showHide: '',
      dataArray: [],
      isDueOnModelVisible: false,
      alertText: '',
      cloneAlert: false,
      vaccinationDataClone: [],
      loginRole: '',
      vaccinationSites: VACCINATION_SITES
    };
    this.CloneListArr = [];
    this.disabledDate = this.disabledDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }


  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  componentDidMount() {

    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_type, reference_id } = params;

    if (LS_SERVICE.get('user_type') === USERTYPES.nurse) { //2: nurse, 1: doctor
        this.setState({loginRole: 'nurse'});
		}

    const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

    if (+isPracticingHospitalId === +hospital_id) {
      this.setState({
        isPracticing: true
      })
    }
    this.setState({ current_date: moment().add(-1, 'days') });

    const patientUrlData = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }
    this.setState({
      hospital_id,
      patient_id,
      patientUrlData
    }, () => {
      //let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
      //hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);
      //let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;
      //console.log('edit>>', allowEdit);
      //this.setState({allowEdit, reference_id})
      if (LS_SERVICE.has('hospitalConfigData')) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
        this.getPatientData();
      }

      if(LS_SERVICE.get('user_type') === USERTYPES.nurse) {
        this.getPatientData();
      }

      
      // if (LS_SERVICE.has('hospitalConfigData')) {
      //   const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
      //   let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
      //   headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
      //   this.getPatientData();
      // }
    });


  }

  getPatientData = _ => {
    const { match: { params } } = this.props;
    const { patient_id } = params;
    Axios.get(PATIENT_DATA({ patient_id }))
      .then(success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results.qmsToken ? success.data.data.results.qmsToken.qms_token_id : null
        }

        this.setState({
          patient
        }, () => {
          this.loadData();
        })
      })
      .catch(err => console.log(err))
  }

  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  }

  // For Modal popup
  handleModalPopup = (e, popup) => {
    if (e !== null) e.preventDefault();
    this.setState({
      [popup]: !this.state[popup]
    })
  }

  onChangeDate = (date, dateString, id, vaccineDataIndex = null, subListIndex = null, clone_list = null) => {
    const { record, vaccinationData } = this.state;
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_type, reference_id } = params;
    if (dateString != '') {
      record[id]['givenon'] = dateString;
    } else {
      record[id]['givenon'] = '';
    }
    this.setState({ record })
    if (vaccineDataIndex !== null && clone_list == null) {
      vaccinationData[vaccineDataIndex]['givenon'] = dateString ? dateString : ''
      vaccinationData[vaccineDataIndex]['reference_id'] = reference_id;
      this.setState({ vaccinationData })
    } else if (subListIndex !== null && clone_list !== null) {
      vaccinationData[vaccineDataIndex].subList[subListIndex]['givenon'] = dateString ? dateString : ''
      vaccinationData[vaccineDataIndex].subList[subListIndex]['reference_id'] = reference_id;
      this.setState({ vaccinationData })
    }
    this.setState({ notClickable: true })
  }

  onExpiryChangeDate = (date, dateString, id, vaccineDataIndex = null, subListIndex = null, clone_list = null) => {
    const { record, vaccinationData } = this.state;
    if (dateString != '') {
      //Add 1st date for month default, change date format start 
      let dateDefault = "1, ";
      let formattedDate = dateDefault.concat(dateString);
      dateString = moment(formattedDate).format('DD/MM/YYYY');
      //Add 1st date for month default, change date format end
      record[id]['expiry_date'] = dateString;
    } else {
      record[id]['expiry_date'] = '';
    }
    this.setState({ record })
    if (vaccineDataIndex !== null && clone_list == null) {

      vaccinationData[vaccineDataIndex]['expiry_date'] = dateString ? dateString : ''
      this.setState({ vaccinationData })
    } else if (subListIndex !== null && clone_list !== null) {
      vaccinationData[vaccineDataIndex].subList[subListIndex]['expiry_date'] = dateString ? dateString : ''
      this.setState({ vaccinationData })
    }
    this.setState({ notClickable: true })
  }

  print() {
    let { patient, hospital_id } = this.state;

    let selHospitalData = null;

    //let selHospitalData = Object.values(LS_SERVICE.get("slot_hospitals")).find(item => item.id == hospital_id);

    if(this.state.loginRole === 'nurse') {
      selHospitalData = Object.values(LS_SERVICE.get("slot_hospitals")).find(item => item.hospital_id == hospital_id);
    } else {
      selHospitalData = Object.values(LS_SERVICE.get("slot_hospitals")).find(item => item.id == hospital_id);
    }

    let selHospitalDataImage = selHospitalData.hospital_image;
    var divToPrint = document.getElementById('printMe');
    var htmlToPrint = '<!DOCTYPE html>';
    htmlToPrint += '<html>';
    htmlToPrint += '<head><title style="font-family: Arial, sans-serif, Helvetica; color: #111; font-size: 16px; font-weight: 500;">Vaccination Chart</title>';
    htmlToPrint += '<style type="text/css">' +
      'table{' +
      'margin-bottom:1rem;' +
      'width:100%;' +
      'border-collapse:collapse;' +
      'display:table;' +
      'border-spacing: 2px;' +
      'padding:0.5em;' +
      'font-family:Arial, sans-serif;' +
      '}' +
      'table thead th{' +
      'border:solid 1px #ccc;' +
      'padding:.75rem;' +
      'background:#f4f4f4;' +
      'color:#333;' +
      'font-family:Arial, sans-serif, Helvetica;' +
      'font-weight: 500;' +
      '}' +
      'table td {' +
      'border:solid 1px #ccc;' +
      'padding:12px 10px;' +
      'color:#555;' +
      'font-family:Arial, sans-serif, Helvetica;' +
      'font-size:14px;' +
      '}' +
      'table tbody tr td.missed{ background:#ffcccc}' +
      'table tbody tr td.due-now{ background:#d6eaff}' +
      '@media print {body {-webkit-print-color-adjust: exact;}}' +
      'td.hideTD{display:none}' +
      'tr.hideTR{display:none}' +
      'td.showTD{display:table-cell}' +
      '.hide{display:none}' +
      '.show{display:block}' +
      'div.hideWhilePrint{display:none}' +
      '@page {margin:1px}' +
      '</style>';

    htmlToPrint += '</head><body>'

    htmlToPrint += '<div style="width: 100%; display: table;">' +
      '<div style="display: table-row; height: 60px;">' +
      '<div style="width: 50%;display: table-cell;vertical-align: top;font-weight: bold; font-family: Roboto, sans-serif, Helvetica; color: #333; padding: 5px 0px; font-size: 22px;">' +
      '<span>Vaccination Chart</span>' +
      '</div>' +
      '<div style="display: table-cell;">' +
      '<span style="display:block;float: right;">' +
      '<img style="height:auto;width: 120px;" src="' + selHospitalDataImage + '" ' +
      'alt="Hospital Image"></img></span>' +
      '</div>' +
      '</div>' +
      '</div>';

    htmlToPrint +=
      '<div style="width: 100%; display: table;background-color: #f4f4f4" >' +
      '<div style="margin-bottom:10px ;vertical-align: middle; color: #555; padding: 7px 10px 5px 12px; font-size: 18px;' +
      'font-family: Roboto; sans-serif, Helvetica">' +
      '<p style="margin-bottom: 1px;"><strong>Patient Name: </strong>'
      + (patient.first_name !== undefined && patient.first_name !== null ? patient.first_name.charAt(0).toUpperCase() + patient.first_name.slice(1) + ' ' : '')
      + (patient.last_name !== undefined && patient.last_name !== null ? patient.last_name.charAt(0).toUpperCase() + patient.last_name.slice(1) + ', ' : '')
      + (patient.dob !== undefined && patient.age !== null ? this.getPatientAge(patient.dob) + ', ' : '')
      + (patient.gender !== undefined && patient.gender !== null ? patient.gender : null)
      + '</p>' +
      '<p><strong>Address: </strong>'
      + (patient.address_1 !== undefined && patient.address_1 !== null ? patient.address_1 + ', ' : '')
      + (patient.address_2 !== undefined && patient.address_2 !== null ? patient.address_2 + ', ' : '')
      + (patient.city !== undefined && patient.city !== null ? patient.city + ', ' : '')
      + (patient.country !== undefined && patient.country !== null ? patient.country : null)
      + '</p>' +
      '</div>' +
      '<div style="display: table-cell;padding-right:30px;">' +
      '<span style="display:block;float: right;height: 15px;">' +
      '<input style="width:14px;height:14px;background-color:#d6eaff;border:#d6eaff;">' +
      '<label style="margin:5px 5px;">Due Now</label>' +
      '<input style="width:14px;height:14px;background-color:#ffcccc;border:#ffcccc;">' +
      '<label style="margin:5px 5px;">Missed</label>' +
      '</span>' +
      '</div>' +
      '</div>';
    htmlToPrint += '</body></html>';
    htmlToPrint += divToPrint.innerHTML;
    let newWin = window.open("");
    let is_chrome = Boolean(newWin.chrome);

    newWin.document.write(htmlToPrint);
    newWin.document.close(); // necessary for IE >= 10
    if (is_chrome) { // wait until all resources loaded 
      newWin.onload = function () { // wait until all resources loaded 
        newWin.focus(); // necessary for IE >= 10
        newWin.print();  // change window to mywindow
        newWin.close();// change window to mywindow
      };
    }
    else {
      newWin.document.close(); // necessary for IE >= 10
      newWin.focus(); // necessary for IE >= 10
      newWin.print();
      newWin.close();
    }
  }


  tillCurrentAge = (e) => {
    let dob = this.state.patient.dob;
    let currentDate = moment();
    let DOB = moment(dob);
    let noOfDays = currentDate.diff(DOB, 'days');
    if (this.state.tillCurrent === 0) { this.setState({ tillCurrent: 1, currentAge: noOfDays }) } else { this.setState({ tillCurrent: 0 }) }
  }

  onHandleViewChart = (e) => {

    let patientDob = this.state.patient.dob;
    let todayDate = moment();
    let DOBConversion = moment(patientDob);
    let patientAgeInYears = todayDate.diff(DOBConversion, 'years');
    if (patientAgeInYears > PAEDRATICAGELIMIT) { this.setState({ showHide: 'hide' }) }

    e.preventDefault();
    this.setState({
      viewChartSection: !this.state.viewChartSection
    })
    const { match: { params } } = this.props;
    const { patient_id,reference_id } = params;
    let hospital_id = LS_SERVICE.get('slot_hospital_id');
    Axios.get(COMBINE_VACCINATION_DATA({ patient_id,reference_id,hospital_id })).
      then((response) => {
        if (response.data) {
          let vaccineRecommended = response.data.iap_recommended;
          let vaccineRecommendedArr = vaccineRecommended.sort(function (a, b) {
            return a.age_in_days > b.age_in_days ? 1 : -1;
          });
          this.setState({ vaccinations: vaccineRecommendedArr, vaccinationsOptional: response.data.optional });
        }
      })
      .catch((error) => {
        console.log(error)
      })
      this.getPatientData();
  }

  getPatientAge(dob) {
    var ageD = Math.floor(moment().diff(moment(dob, 'YYYY-MM-DD'), 'D') / (1000 * 3600 * 24));
    var ageM = moment().diff(moment(dob, 'YYYY-MM-DD'), 'M');
    var ageY = ageM / 12;
    var remainM = ageM % 12;
    ageY = Math.floor(ageY);
    var ageText = '';
    if (ageM == 0)
      ageText = ageD + ' Day' + (ageD == 1 ? '' : 's');
    else if (ageY < 1)
      ageText = ageM + ' Month' + (ageM == 1 ? '' : 's');
    else if (ageY <= 5) {
      if (remainM != 0) {
        ageText = ageY + ' Year' + (ageY == 1 ? '' : 's ') + remainM + ' Month' + (remainM == 1 ? '' : 's');
      } else {
        ageText = ageY + ' Year' + (ageY == 1 ? '' : 's');
      }
    }
    else
      ageText = ageY + ' Years';
    return ageText;
  }

  handleChange = async (e, field, index, vaccineDataIndex = null, subListIndex = null, clone_list = null) => {
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_type, reference_id } = params;
    const { record, vaccinationData } = this.state;
    record[index][field] = e.target.value.toUpperCase()
    this.setState({ record });
    // e.preventDefault();
    if (vaccineDataIndex !== null && clone_list == null) {
      if (field == "outside") {
        if (e.currentTarget.checked) {
          vaccinationData[vaccineDataIndex][field] = 'on';
        } else {
          vaccinationData[vaccineDataIndex][field] = 0;
        }
      } else if (field == "remark") {
        vaccinationData[vaccineDataIndex][field] = this.titleCase(e.target.value);
      } else if (field == "batch") {
        vaccinationData[vaccineDataIndex][field] = e.target.value.toUpperCase();
      } else if (field == "site") {        
        vaccinationData[vaccineDataIndex][field] = e.target.value;
      }
      else {
        vaccinationData[vaccineDataIndex][field] = e.target.value;

      }
      vaccinationData[vaccineDataIndex]['reference_id'] = reference_id;
      await this.setState({ vaccinationData })
    } else if (subListIndex !== null && clone_list !== null) {
      if (field == "outside") {
        if (e.currentTarget.checked) {
          vaccinationData[vaccineDataIndex].subList[subListIndex][field] = 'on';
        } else {
          vaccinationData[vaccineDataIndex].subList[subListIndex][field] = 0;
        }
      } else if (field == "remark") {
        vaccinationData[vaccineDataIndex].subList[subListIndex][field] = this.titleCase(e.target.value);
      } else if (field == "batch") {
        vaccinationData[vaccineDataIndex].subList[subListIndex][field] = e.target.value.toUpperCase();
      } else if (field == "site") {
        vaccinationData[vaccineDataIndex].subList[subListIndex][field] = e.target.value;
      }
      else {
        vaccinationData[vaccineDataIndex].subList[subListIndex][field] = e.target.value;

      }
      vaccinationData[vaccineDataIndex].subList[subListIndex]['reference_id'] = reference_id;
      this.setState({ vaccinationData })
    }
    await this.setState({ notClickable: true })
  }

  titleCase = (str) => {
    var newString = str.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
    return newString;
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let vaccination_data = Object.values(this.state.vaccinationData);
    let patient = this.state.patient;
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_type, reference_id } = params;
    let chk_batch = false;
    if (vaccination_data) {
      vaccination_data.map((item, key) => {
        if (item.item != '' && item.item > 0 && (item.givenon === '')) {
          e.preventDefault();
          notification['error']({
            description: "Given On is required for " + item.name + ' ' + item.dose_number + ' to proceed'
          });
          chk_batch = true;
          return;
        }
        else if ((item.outside === 'on' && item.givenon === '')) {
          e.preventDefault();
          notification['error']({
            description: "Given On is required for " + item.name + ' ' + item.dose_number + ' to proceed'
          });
          chk_batch = true;
          return;
        }
        else if ((item.outside == 0) && (item.givenon !== '' && (item.item === '' || item.item === 0))) {
          e.preventDefault();
          notification['error']({
            description: "Given Outside or Vaccine name is required for " + item.name + ' ' + item.dose_number + ' to proceed'
          });
          chk_batch = true;
          return;
        }
        else if ((item.remark !== '' || item.batch !== '' || item.expiry_date != null) && ((item.outside === 0) && (item.item == '' || item.item === 0))) {
          e.preventDefault();
          notification['error']({
            description: "Vaccine name is required for " + item.name + ' ' + item.dose_number + ' to proceed'
          });
          chk_batch = true;
          return;
        }

       //sublit
       if(item.subList && item.subList.length>0){
        let isRequiredSubItem = [];
        item.subList.map((sublist, key) => {
          if (sublist.item != '' && sublist.item > 0 && (sublist.givenon === '')) {
            isRequiredSubItem.push(key);
            this.setState({ isRequiredSubItem : isRequiredSubItem});
            e.preventDefault();
            notification['error']({
              description: "Given On is required for " + sublist.name + ' ' + sublist.dose_number + ' to proceed'
            });
            chk_batch = true;
            return;
          }
          else if ((sublist.outside === 'on' && sublist.givenon === '')) {
            e.preventDefault();
            isRequiredSubItem.push(key);
            this.setState({ isRequiredSubItem : isRequiredSubItem});
            notification['error']({
              description: "Given On is required for " + sublist.name + ' ' + sublist.dose_number + ' to proceed'
            });
            chk_batch = true;
            return;
          }
          else if ((sublist.outside == 0) && (sublist.givenon !== '' && (sublist.item === ''))) {
            e.preventDefault();
            isRequiredSubItem.push(key);
            this.setState({ isRequiredSubItem : isRequiredSubItem});
            notification['error']({
              description: "Given Outside or Vaccine name is required for " + sublist.name + ' ' + sublist.dose_number + ' to proceed'
            });
            chk_batch = true;
            return;
          }
          // else if (sublist.outside === 1 && sublist.givenon === null) {
          //   e.preventDefault();
          //   isRequiredSubItem.push('subItem'+key);
          //   this.setState({ isRequiredSubItem : isRequiredSubItem});
          //   notification['error']({
          //     description: "Given Outside and Given On is required for " + sublist.name + ' ' + sublist.dose_number + ' to proceed'
          //   });
          //   chk_batch = true;
          //   return;
          // }
          else if ((sublist.remark !== '' || sublist.batch !== '' || sublist.expiry_date != null) && ((sublist.outside === 0) && (sublist.item == '' || sublist.item === 0))) {
            e.preventDefault();
            isRequiredSubItem.push(key);
            this.setState({ isRequiredSubItem : isRequiredSubItem});
            notification['error']({
              description: "Vaccine name is required for " + sublist.name + ' ' + sublist.dose_number + ' to proceed'
            });
            chk_batch = true;
            return;
          }
        })
       } 
      })
    }

    //get the current visit vaccination
    let currentReference = this.currentVisit(vaccination_data, reference_id);
    const PARAMS = {
      vaccination_data: currentReference,
      patient_id: patient.patient_id,
      encounter_id: reference_id,
      reference_type: 0,
    }
    if (chk_batch == false) {
      await Axios.post(VaccinationAdd, PARAMS).
        then((response) => {
          if (window.location.href.indexOf("plan") > -1) {
            //const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];

            const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
            if (!isTodayVisitArr.includes(reference_id)) {
              this.props.history.push({
                pathname: `/patient/todays-visit/plan/prescription/${hospital_id}/${patient.patient_id}/${reference_type}/${reference_id}`,
                state: { patient }
              })
            } else {
              this.props.history.push({
                pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${reference_type}/${reference_id}`,
                state: { patient_id }
              })
              LS_SERVICE.set('blockname', 'plan');
            }



          } else {
            if (this.state.notClickable) {
              this.loadData();
              notification.success({
                message: response.data.message,
                placement: 'topRight'
              });
            }
          }


        })
        .catch((error) => {
          console.log(error)
        })
    }

  }

  currentVisit(vaccination_data, reference_id) {
    let data = Object.values(vaccination_data);
    var today = moment().format('YYYY-MM-DD');
    let filterData = [];
    let created_at = '';
    data?.map((val, i) => {

      //console.warn('reference_id',val.reference_id+'---'+reference_id);return;
      created_at = moment(val.created_at).format('YYYY-MM-DD');
      if ((val.givenon === null || typeof val.givenon === undefined || val.givenon == '')) {
        delete data[i];
      }
      //else if (parseInt(val.reference_id) == reference_id) {
        filterData.push(val);
      //}
      // else if(val.reference_id == reference_id && created_at >= today){
      //   filterData.push(val);
      // }  

    });
    return filterData;
  }

  onContinue = (e) => {
    let patient = this.state.patient;
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_type, reference_id } = params;
    const isTodayVisitArr = LS_SERVICE.has('isTodayVisit') ? LS_SERVICE.get('isTodayVisit') : [];
    if (!isTodayVisitArr.includes(reference_id)) {
      this.props.history.push({
        pathname: `/patient/todays-visit/plan/prescription/${hospital_id}/${patient.patient_id}/${reference_type}/${reference_id}`,
        state: { patient }
      })
    } else {
      this.props.history.push({
        pathname: `/patient/todays-visit/${hospital_id}/${patient_id}/${reference_type}/${reference_id}`,
        state: { patient_id }
      })
      LS_SERVICE.set('blockname', 'plan');
    }
  }

  loadData = () => {
    this.setState({ notClickable: false })
    const staff_id = LS_SERVICE.get('staff_id');
    const hospital_id = LS_SERVICE.get('slot_hospital_id');
    const speciality_id = LS_SERVICE.get('speciality_id');
    const { match: { params } } = this.props;
    const { reference_type, reference_id } = params;
    const patient_data = this.state.patient;
    if (patient_data != undefined && patient_data.dob != undefined && patient_data.dob != null) {
      this.setState({ birthDate: patient_data.dob });
    }
    const list = Axios.post(VaccinationListV2, qs.stringify({hospital_id: LS_SERVICE.get('slot_hospital_id')}));
    const item = Axios.post(VaccinationItemList, qs.stringify({ drug_class: PAEDRATICVACCINEID, hospital_id: LS_SERVICE.get('slot_hospital_id')}));
    const chart_list = Axios.post(VaccinationRecordList, qs.stringify({ patient_id: patient_data.patient_id }));
    //const chart_list = Axios.post(VaccinationByRefence, qs.stringify({ reference_type: reference_type, reference_id:reference_id }));
    Axios.all([list, item, chart_list])
      .then(Axios.spread((...responses) => {

        const responseOne = responses[0];
        const responseTwo = responses[1];
        const responseThree = responses[2];
        const confInfo = Object.values(responseOne.data);
        const item = Object.values(responseTwo.data);
        const chart_list = Object.values(responseThree.data);

        this.setState({
          itemData: item[1]
        });
        let record = this.state.record;
        let iap = '';
        let optional = '';
        if (confInfo[0] != undefined) {
          let arrayA = [];
          let patient_dob = patient_data.dob;
          let age = this.getPatientAge(patient_dob);
          let max_due_date = moment().add(30, 'days');
          let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
          hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);
          let allowEdit;
          let subAllowEdit;
          confInfo[1]?.map((val, i) => {

            let dose_start_age = parseInt(val.startAge);
            let dose_start_duration = 'days';
            if (val.startAgeDuration == 'Day') {
              dose_start_duration = 'days';
            }
            else if (val.startAgeDuration == 'Week') {
              dose_start_duration = 'weeks';
            }
            else if (val.startAgeDuration == 'Month') {
              dose_start_duration = 'months';
            }
            else if (val.startAgeDuration == 'Year') {
              dose_start_duration = 'years';
            }
            let due_on = moment(patient_dob).add(dose_start_age, dose_start_duration);

            let dose_end_age = parseInt(val.endAge);
            let dose_end_duration = 'days';
            if (val.endAgeDuration == 'Day') {
              dose_end_duration = 'days';
            }
            else if (val.endAgeDuration == 'Week') {
              dose_end_duration = 'weeks';
            }
            else if (val.endAgeDuration == 'Month') {
              dose_end_duration = 'months';
            }
            else if (val.endAgeDuration == 'Year') {
              dose_end_duration = 'years';
            }
            let end_on = moment(patient_dob).add(dose_end_age, dose_end_duration).format('DD/MM/YYYY');


            // if (max_due_date.isAfter(due_on)) {
              due_on = due_on.format('DD/MM/YYYY');
              confInfo[1][i] = { ...val, id: 0, vaccine_id: val.id, item: '', created_at: moment(), creted_by: staff_id, reference_id: 0, enddate: end_on, duedate: due_on, givenon: '', outside: 0,vac_site: '', batch: '', remark: '', dob: patient_dob, dose_number: val.scheduleDoseNo, start_age: val.startAge, duration: val.startAgeDuration, type: val.type, dose_id: val.id, allowEdit: true,emr_vaccination_id: val.id };

              if (chart_list != undefined && chart_list.length > 0) {
                chart_list?.map((value, j) => {

                  if (val.vaccinationId == value.vacc_id && val.scheduleDoseNo == value.dose_number) {

                    allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[value.reference_id], 'button') : true;
                    //console.log('allowEdit>>>', allowEdit + '---'+value.reference_id +'----'+ LS_SERVICE.get('appointmentCompletedTime')[value.reference_id])
                    if (parseInt(value.reference_id) != parseInt(reference_id)) {
                      allowEdit = false;
                    }
                    let finalSubList = [];
                    if(value.subList && value.subList.length>0){
                    for (var data of value.subList) {
                      subAllowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[value.reference_id], 'button') : true;
                      if (parseInt(data.reference_id) != parseInt(reference_id)) {
                        subAllowEdit = false;
                      }
                      let obj = {
                        batch: data.batch,
                        clone_list: data.clone_list,
                        createdAt: data.createdAt,
                        createdBy: data.createdBy,
                        deleted: data.deleted,
                        deleteddAt: data.deleteddAt,
                        deleteddBy: data.deleteddBy,
                        dob: value.dob,
                        dose_id: data.dose_id,
                        dose_number: data.dose_number,
                        due_date: data.due_date,
                        duration: value.duration,
                        emr_vaccination_id: data.emr_vaccination_id,
                        expiry_date: data.expiry_date,
                        givenon: data.given_on,
                        outside: data.given_outside,
                        vac_site:data.vac_site,
                        id: data.id,
                        item: data.item_id,
                        name: data.name,
                        patient_id: data.patient_id,
                        reference_id: data.reference_id,
                        reference_type: data.reference_type,
                        remark: data.remarks,
                        start_age: value.start_age,
                        type: data.type,
                        updatedAt: data.updatedAt,
                        updatedBy: data.updatedBy,
                        vacc_id: data.vacc_id,
                        vaccinationId: data.vacc_id,
                        scheduleDoseNo: data.dose_number,
                        allowEdit:subAllowEdit,
                        staff: data.docDetails
                      };
                      finalSubList.push({ ...obj });
                    }
                  }

                    confInfo[1][i] = { ...val, created_at: value.createdAt, allowEdit: allowEdit, creted_by: value.createdBy, id: value.id, reference_id: value.reference_id, enddate: end_on, batch: value.batch ? value.batch : '', remark: value.remarks ? value.remarks : '', duedate: value.due_date, givenon: value.given_on ? value.given_on : '', outside: value.given_outside, vac_site: value.vac_site, item: value.item_id, expiry_date: value.expiry_date, dose_number: val.scheduleDoseNo, staff: value.docDetails, subList: finalSubList, emr_vaccination_id: value.dose_id, dob: value.dob, start_age: value.start_age, duration: value.duration,dose_id: value.dose_id };
                  }
                })
              }
              record[val.vaccinationId + '_' + val.scheduleDoseNo] = { id: 0, vaccine_id: val.id, vacc_id: val.vaccinationId, name: val.name, duedate: due_on, start_age: val.startAge, duration: val.startAgeDuration, type: val.type, outside: 0,vac_site: '', batch: '', remark: '', dob: patient_dob, dose_number: val.scheduleDoseNo, item: '', givenon: '', dose_id: val.id, expiry_date: '', }
              if (val.type == 'M') {
                iap = true;
              }

              if (val.type == 'O') {
                optional = true;
              }

              arrayA.push(confInfo[1][i]);
            //}
          })

          if (iap != '') {
            this.setState({ IAPRecord: true });
          }

          if (optional != '') {
            this.setState({ OptionalRecord: true })
          }

          arrayA.sort((a, b) => {
            var ad = moment(a.duedate, 'DD/MM/YYYY');
            var bd = moment(b.duedate, 'DD/MM/YYYY');
            if (ad == bd) return 0;
            return bd.isAfter(ad) ? -1 : 1;
          });
          // arrayA.sort((a, b) => (a.name > b.name) ? 1 : -1);
          this.setState({
            record,
            vaccinationData: arrayA
          });


        }

      }))
  }

  disabledDate(current, duedate) {
    //return current && (current < moment(duedate).startOf('day') || current < moment(this.state.birthDate).startOf('day'));
    return current && (current < moment(this.state.birthDate).startOf('day') || current > moment());
  }

  disabledPastDate(current) {
    return current && (current < moment());
  }

  dateOfBirthCal(dob) {
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  handleAlertMCancel = async () => {
    await this.state.CloneListArr.pop();
    await this.setState(prevState => ({
      ...prevState,
      isDueOnModelVisible: false,
      vaccinationDataClone: [],
      /* CloneListArr: [] */
    }));
    //this.CloneListArr = [];
  };

  rowClone = async (event, vaccineDataIndex, data, vaccinatedId, editId = null, emr_vaccination_id = null) => {
    let tempDate = [];
    
    const { vaccinationData, vaccinationDataClone } = this.state;
    const { match: { params } } = this.props;
    const { reference_id } = params;

    if (vaccinationDataClone && vaccinationDataClone.length === 0) {

      let FilterPrev = this.state.vaccinationData.filter(function (e) {
        return e.emr_vaccination_id == emr_vaccination_id;
      });

      if (FilterPrev[0].subList && FilterPrev[0].subList.length > 0) {
        let lastElement = FilterPrev[0].subList.length - 1;
        let lastIndexData = FilterPrev[0].subList[lastElement];
        var given_on_date = lastIndexData.givenon;

      } else {
        given_on_date = FilterPrev[0].givenon;
      }
      var givenDuration = data.givenDuration;
      var givenNo = data.givenNo;
      var vaccine_name=data.name+' '+data.dose_number;

      var alertText = 'Ideal gap period defined between ' + vaccine_name + ' is ' + givenNo + ' ' + givenDuration + '. Are you sure you want to continue?';
      let objData = {
        active: data.active,
        allowEdit: true,
        batch: '',//data.batch,
        created_at: data.created_at,
        creted_by: LS_SERVICE.get('staff_id'),
        dob: this.state.patient.dob,
        dose_id: data.dose_id,
        dose_number: data.dose_number,
        duedate: data.duedate,
        duration: FilterPrev[0].startAgeDuration,
        endAge: data.endAge,
        endAgeDuration: data.endAgeDuration,
        enddate: data.enddate,
        givenon: '',//data.givenon,
        id: 0,
        item: '',//data.item,
        name: data.name,
        noOfDoses: data.noOfDoses,
        outside: 0,
        vac_site: '',
        reference_id: parseInt(reference_id),
        remark: '',//data.remark,
        scheduleDoseNo: data.scheduleDoseNo,
        startAge: FilterPrev[0].startAge,
        startAgeDuration: data.startAgeDuration,
        start_age: FilterPrev[0].startAge,
        type: data.type,
        vaccinationId: data.vaccinationId,
        vaccinationRange: data.vaccinationRange,
        vaccine_id: data.vaccine_id,
        vaccine_list: data.vaccine_list,
        emr_vaccination_id: data.emr_vaccination_id
      };

      let obj = [];
      for(let index = 1; index <= objData.noOfDoses; index++){
        let newObj = {...objData};
        newObj.scheduleDoseNo = index;
        newObj.dose_number = index;
        obj.push(newObj);
      }

      if (givenNo && givenDuration) {
        let pastDate = moment(given_on_date).format('YYYY/MM/DD');
        let todayDate = moment().format('YYYY/MM/DD'); //moment().subtract(1,'day').format('YYYY/MM/DD');
        let emr_given_no = parseInt(givenNo);
        let given_duration = 'days';

        if (givenDuration == 'day') {
          given_duration = 'days';
        }
        else if (givenDuration == 'week') {
          given_duration = 'weeks';
        }
        else if (givenDuration == 'month') {
          given_duration = 'months';
        }
        else if (givenDuration == 'year') {
          given_duration = 'years';
        }
        let end_date = moment(pastDate).add(emr_given_no, given_duration).format('YYYY/MM/DD');
        const newTodayDate = new Date(todayDate);
        const newGivenDate = new Date(end_date);
        if (newTodayDate < newGivenDate) {
          await this.setState(prevState => ({
            ...prevState,
            alertText: alertText,
            isDueOnModelVisible: true,
            cloneAlert: true,
          }));
        } else {
          await this.setState(prevState => ({
            ...prevState,
            isDueOnModelVisible: false,
            cloneAlert: false,
            /* vaccinationDataClone: [], */
            /* CloneListArr: [] */
          }));
        }
      }

      this.CloneListArr = [...this.CloneListArr, obj];

      await this.setState(prevState => ({
        ...prevState,
        CloneListArr: this.CloneListArr
      }));

      if (vaccineDataIndex !== null) {
        vaccinationData.map((v, k) => {
          if (v.vaccinationId === vaccinatedId && vaccineDataIndex === k && v.id === 0) {

            var arrayList = this.state.CloneListArr.filter(function (e) {
              return e.vaccine_id == data.vaccine_id;
            });

            tempDate.push(
              {
                ...vaccinationData[k], subList: arrayList
              });
          } else if (v.vaccinationId === vaccinatedId && vaccineDataIndex === k && v.id === editId && v.emr_vaccination_id === data.emr_vaccination_id) {

            var arrayList2 = vaccinationData[vaccineDataIndex].subList.filter(function (e) {
              return e.emr_vaccination_id === v.emr_vaccination_id;
            });

            var FinalArray = arrayList2.concat(obj);

            this.setState(prevState => ({
              ...prevState,
              CloneListArr: []
            }));

            tempDate.push(
              {
                ...vaccinationData[k], subList: FinalArray
              });

          } else {

            tempDate.push(
              {
                ...vaccinationData[k]
              });
          }
        });

        if (!this.state.cloneAlert) {
          await this.setState({ vaccinationData: tempDate })
        } else {
          await this.setState({ vaccinationDataClone: tempDate })
        }

      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        alertText: '',
        isDueOnModelVisible: false,
        cloneAdd: false,
        vaccinationData: vaccinationDataClone,
        vaccinationDataClone: []
      }));
    }

  }

  htmlData(event, index) {
    return (
      <div className="container">
        <h3>React Datepicker Example</h3>
        <form >
          <div className="form-group">
            <label>Select Date: </label>
            <DatePicker
              name="startDate"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="form-group">
            <button className="btn btn-success">Add Date</button>
          </div>
        </form>
      </div>
    );
  }

  render() {    
    const { viewChartSection, message, reference_id, patient, vaccinationData, record, itemData, vaccinations, vaccinationsOptional, IAPRecord, OptionalRecord, birthDate, vaccinationSites } = this.state;
    const dateFormat = "DD/MM/YYYY";
    let currentUrl = '';
    let content = '';
    let subContent = '';
    let patientDob = this.dateOfBirthCal(new Date(this.state.birthDate));
   
    if (window.location.href.indexOf("plan") > -1) {
      currentUrl = 'Plan';
    }
    return (
      <>
        {/* <!-- Page sidebar--> */}
        {(patient != undefined && patient?.patient_id != undefined) ? <AsideLeft patient_id={patient?.patient_id} reference_id={reference_id} /> : null}


        <div className="content-wrapper">
          <div className="container-fluid">

            <div className="row">
              {patient != undefined && patient.patient_id != undefined && patient.patient_id != null && currentUrl == "Plan" ?
                <SubHeader patient={patient} />
                : null}
              {
                currentUrl == "Plan"
                  ?
                  <>
                    {
                      !viewChartSection
                        ?
                        <div className="col-4 text-right">
                          <a href="#" className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</a>
                        </div>
                        :
                        <div className="col-4 text-right">
                          <a href="#" className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => this.onHandleViewChart(e)}>BACK</a>
                        </div>
                    }
                  </>
                  :
                  null
              }
            </div>

            <div className="row emrfrm mb-5">
              <div className="col-lg-12">
                <h1 className="page-title float-left">Vaccination Record</h1>
                {currentUrl != "Plan" && patient != undefined && patient.dob != undefined && patient.dob != null ?
                  <div className="float-right">
                    {!viewChartSection ?
                      <a href="#" className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</a>
                      :
                      <a href="#" className="btn btn-outline-secondary px-4 small text-uppercase" onClick={e => this.onHandleViewChart(e)}>BACK</a>
                    }
                  </div>
                  : ''}
              </div>

              {!viewChartSection ?
                <>
                  <Form className="emrfrm1 emrfrm mt-4">
                    {patient != undefined && patient.dob != undefined && patient.dob != null ?
                      <div className="col-lg-12">
                        <ul className="nav nav-pills justify-content-center myhealthcare-tabs">
                          <li className="nav-item">
                            <a href="#list-tab" className={!patientDob || (patientDob && patientDob < 18) ? 'nav-link active' : 'nav-link '} data-toggle="tab">IAP Recommended</a>
                          </li>
                          <li className="nav-item">
                            <a href="#order-set-tab" className={ patientDob && patientDob >= 18? 'nav-link active' : 'nav-link '} data-toggle="tab">Optional</a>
                          </li>
                        </ul>
                        <div className="text-right mb-2">
                          <ul className="immunization_status">
                            <li className="due-now"><i></i> Due Now</li>
                            <li className="missed"><i></i> Missed</li>
                          </ul>
                        </div>
                        <div className="tab-content pb-5 mb-5">
                          <div className={!patientDob || (patientDob && patientDob < 18) ? 'tab-pane fade show active' : 'tab-pane fade'} id="list-tab" role="tabpanel">
                            <div className="table-responsive">
                              <table className="table table-default">
                                <thead>
                                  <tr>
                                    <th style={{ "width": "185px", "text-align": "center" }}>Vaccine</th>
                                    <th style={{ "width": "180px", "text-align": "center" }}>Vaccine Name</th>
                                    <th style={{ "width": "140px", "text-align": "center" }}>DUE DATE</th>
                                    <th style={{ "width": "130px", "text-align": "center" }}>Given Outside?</th>
                                    <th style={{ "width": "160px", "text-align": "center" }}>Vaccination Site</th>
                                    <th style={{ "width": "140px", "text-align": "center" }}>Given On</th>
                                    <th style={{ "width": "125px", "text-align": "center" }}>Batch</th>
                                    <th style={{ "width": "140px", "text-align": "center" }}>Expiry Date</th>
                                    <th style={{ "width": "280px", "text-align": "center" }}>Remarks</th>
                                    <th style={{ "width": "20px", "text-align": "center" }}>Info</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {IAPRecord ?
                                    vaccinationData?.map((iterable, i) => {
                                      
                                      let batch = `batch-${i}`, remark = `remark-${i}`, outside = `outside-${i}`, givenon = `givenon-${i}`, name = `name-${i}`, expiry_date = `expirydate-${i}`;
                                      if (iterable.type == 'M') {
                                        let checked = false;
                                        if (iterable.outside == 1) {
                                          checked = true;
                                        }
                                        let class_name = '';
                                        
                                        var today = moment();
                                        let dueDate = moment(this.state.patient.dob).add(iterable.startAge, iterable.startAgeDuration + 's');
                                        let end_on = moment(this.state.patient.dob).add(iterable.endAge, iterable.endAgeDuration + 's');
                                        var dayDifference = today.diff(end_on, 'days');
                                        var dayDifferencestartAge = today.diff(dueDate, 'days');
                                        if (dayDifferencestartAge + 30 > 0 && dayDifference < 0) { class_name = "due-now"; }
                                        if (dayDifference > 0) { class_name = "missed"; }
                                        if (iterable.givenon !== "") {
                                          class_name = '';

                                        }
                                        let vacc_index = null;
                                        const disableBtnProps = {};
                                        if (iterable.id > 0) {
                                          vacc_index = iterable.id;
                                        }
                                        //console.log('allowEdit>>', allowEdit)
                                        // if(created_at >= moment().format('YYYY-MM-DD'))
                                        if (iterable.allowEdit && iterable.creted_by == LS_SERVICE.get('staff_id')) {
                                          disableBtnProps.disabled = false;
                                        }
                                        else {
                                          disableBtnProps.disabled = true;
                                        }

                                        if (iterable.id != 0) {
                                          content = (
                                            <div>
                                              <p>{(iterable?.staff==null)?'Patient':(iterable?.staff?.designation && iterable?.staff?.designation!=null)?'Dr':''} {iterable.staff ? iterable.staff.first_name : ''} {iterable.staff ? iterable.staff.last_name : ''}, {iterable.givenon ? moment(iterable.givenon).format('D MMM YYYY') : ''}</p>
                                              <p><b>Recorded On :</b>{moment(iterable.created_at).format('D MMM YYYY')}</p>
                                            </div>
                                          );
                                        }

                                        return (
                                          <>
                                            <tr >
                                              <td>{iterable.name} {iterable.scheduleDoseNo} <input type="hidden" className="custom-control-input" name={name} data-id={i} id={name} onChange={(e) => this.handleChange(e, 'name', iterable.vaccinationId + '_' + iterable.scheduleDoseNo)} value={iterable.name} /></td>
                                              <td>

                                                {
                                                  disableBtnProps.disabled == true
                                                    ?
                                                    <select className="table-form-control" {...disableBtnProps} onChange={(e) => this.handleChange(e, 'item', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)}>
                                                      <option value="">Select</option>
                                                      {itemData != undefined && itemData != null ?
                                                        itemData?.map((item, i) => {
                                                          let selected = false;;
                                                          if (iterable.item == item.id) {
                                                            selected = true;
                                                          }
                                                          return (<> <option value={item.id} selected={selected}>{item.name}</option> </>)
                                                        }) : ''}
                                                    </select>
                                                    :
                                                    <select className="table-form-control" {...disableBtnProps} onChange={(e) => this.handleChange(e, 'item', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)}>
                                                      <option value="">Select</option>
                                                      {iterable?.vaccine_list && iterable?.vaccine_list != undefined && iterable?.vaccine_list != null ?
                                                        iterable?.vaccine_list?.map((item, i) => {
                                                          let selected = false;
                                                          if (iterable.item == item.id) {
                                                            selected = true;
                                                          }
                                                          return (<> <option value={item.id} selected={selected}>{item.name}</option> </>)
                                                        }) : ''}
                                                    </select>
                                                }


                                              </td>
                                              <td className={class_name}>{iterable.duedate ? iterable.duedate : iterable.due_date}</td>
                                              <td className="text-center">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                  <input type="checkbox" className="custom-control-input" name={outside} data-id={i} id={outside} {...disableBtnProps} defaultChecked={checked} defaultValue={iterable.outside ? 0 : 'on'} onChange={(e) => this.handleChange(e, 'outside', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} />
                                                  <label className="custom-control-label" htmlFor={outside}></label>
                                                </div>
                                              </td>
                                              <td>
                                                <select
                                                  className="table-form-control"
                                                  onChange={(e) => this.handleChange(e, 'site', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)}
                                                  defaultValue={iterable.vac_site ? iterable.vac_site : ''}
                                                >
                                                  <option value="">Select</option>
                                                  {vaccinationSites?.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.label}</option>
                                                  ))}
                                                </select>
                                              </td>
                                              <td className="datepicker"><DatePicker {...disableBtnProps} name={givenon} disabledDate={(date) => this.disabledDate(date, iterable.duedate ? iterable.duedate : iterable.due_date)} data-id={i} id={iterable.vaccinationId} onChange={(date, dateString) => this.onChangeDate(date, dateString, iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} defaultValue={iterable.givenon ? moment(iterable.givenon) : ''} format={dateFormat} /></td>

                                              <td>
                                                <input type="text" {...disableBtnProps} className="table-form-control remark" placeholder="Type Batch" maxLength="20" name={batch} data-id={i} id={batch} onChange={(e) => this.handleChange(e, 'batch', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} value={iterable.batch} />
                                              </td>
                                              <td className="datepicker"><DatePicker picker="month" {...disableBtnProps} name={expiry_date} disabledDate={this.disabledPastDate} data-id={i} id={iterable.vaccinationId} onChange={(date, dateString) => this.onExpiryChangeDate(date, dateString, iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} defaultValue={iterable.expiry_date ? moment(iterable.expiry_date) : ''} format="MMM, YYYY" /></td>
                                              <td>
                                                <input type="text" className="table-form-control remark" {...disableBtnProps} name={remark} data-id={i} id={remark} onChange={(e) => this.handleChange(e, 'remark', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} value={iterable.remark} placeHolder="Type Remark" />
                                              </td>
                                              <td>
                                                {(iterable.staff==null)?iterable.id !== 0 ? <Popover content={content} title="Recorded By" placement="topRight" trigger="hover"><span className="icon_info"></span></Popover> : '':iterable.id !== 0 ? <Popover content={content} title="Administered By" placement="topRight" trigger="hover"><span className="icon_info"></span></Popover> : ''}</td>
                                            </tr>
                                          </>
                                        )
                                      }
                                    })
                                    : <tr><td colSpan="9">No Record Found!</td></tr>}
                                </tbody>
                              </table>
                            </div>
                            {IAPRecord && currentUrl != "Plan" ?
                              <div className="col-12  fixed-cta">
                                <a href="#" className="btn btn-outline-primary px-4 mr-3 mt-2 mb-2" onClick={e => this.onHandleViewChart(e)}>VIEW CHART</a>

                                <a href="#" className="btn btn-primary px-5 mt-2 mb-2" onClick={e => this.handleSubmit(e)}>SAVE</a>

                              </div> :

                              <div className="col-12 fixed-cta">
                                <a href="#" className="btn btn-outline-primary px-4 mr-3 mt-2 mb-2 " onClick={e => this.onHandleViewChart(e)}>VIEW CHART</a>
                                {IAPRecord && currentUrl === "Plan" ?

                                  <a href="#" className="btn btn-primary px-5 mt-2 mb-2" onClick={e => this.handleSubmit(e)}>CONTINUE</a>
                                  : <a href="#" onClick={e => this.onContinue(e)} type="button" className="btn btn-primary px-5 mt-2 mb-2" >CONTINUE</a>}



                              </div>}

                          </div>

                          <div className={patientDob && patientDob >= 18 ? 'tab-pane fade show active' : 'tab-pane fade'} id="order-set-tab" role="tabpanel">
                            <div className="table-responsive">
                              <table className="table table-default " id="clone_table">
                                <thead>
                                  <tr>
                                    <th style={{ "width": "200px", "text-align": "center" }}>Vaccine</th>
                                    <th style={{ "width": "210px", "text-align": "center" }}>Vaccine Name</th>
                                    <th style={{ "width": "128px", "text-align": "center" }}>Given Outside?</th>
                                    <th style={{ "width": "160px", "text-align": "center" }}>Vaccination Site</th>
                                    <th style={{ "width": "160px", "text-align": "center" }}>Given On</th>
                                    <th style={{ "width": "140px", "text-align": "center" }}>Batch</th>
                                    <th style={{ "width": "155px", "text-align": "center" }}>Expiry Date</th>
                                    <th style={{ "width": "320px", "text-align": "center" }}>Remarks</th>
                                    <th style={{ "width": "20px", "text-align": "center" }}>Info</th>
                                    <th style={{ "width": "15px", "text-align": "center" }}>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {OptionalRecord ?
                                    vaccinationData?.map((iterable, i) => { 
                                      let batch = `batch-${i}`, remark = `remark-${i}`, outside = `outside-${i}`, givenon = `givenon-${i}`, name = `name-${i}`, expiry_date = `expirydate-${i}`;
                                      if (iterable.type == 'O') {
                                        let checked = false;
                                        if (iterable.outside == 1) {
                                          checked = true;
                                        }
                                        let class_name = '';
                                        let duedate = iterable.duedate ? iterable.duedate : iterable.due_date;
                                        var today = moment();
                                        var dateofvisit = moment(iterable.enddate, 'DD-MM-YYYY');
                                        var ageM = today.diff(dateofvisit, 'days');
                                        if (ageM < 0) {
                                          class_name = "due-now";
                                        } else if (ageM > 0) {
                                          class_name = "missed";
                                        }
                                        let vacc_index = null;
                                        const disableBtnProps = {};
                                        if (iterable.id > 0) {
                                          vacc_index = iterable.id;
                                        }

                                        if (iterable.allowEdit && iterable.creted_by == LS_SERVICE.get('staff_id')) {
                                          disableBtnProps.disabled = false;
                                        }
                                        else {
                                          disableBtnProps.disabled = true;
                                        }
                                        if (iterable.id != 0) {
                                          content = (
                                            <div>
                                              <p>{(iterable?.staff==null)?'Patient':(iterable?.staff?.designation && iterable?.staff?.designation!=null)?'Dr':''} {iterable.staff ? iterable.staff.first_name : ''} {iterable.staff ? iterable.staff.last_name : ''}, {iterable.givenon ? moment(iterable.givenon).format('D MMM YYYY') : ''}</p>
                                              <p><b>Recorded On :</b> {moment(iterable.created_at).format('D MMM YYYY')}</p>
                                            </div>
                                          );
                                        }

                                        let showIconPlusMain = false;
                                        if(iterable?.subList?.length == 0){
                                          showIconPlusMain = true;
                                        }else if(iterable?.subList?.length > 0){
                                          if(iterable?.subList[0]?.id == 0){
                                            showIconPlusMain = true;
                                          }
                                        }

                                        return (
                                          <>
                                            <tr className={i} id={'clone_tr' + i}>
                                              
                                              <td>{iterable.name} {iterable.scheduleDoseNo}  <input type="hidden" className="custom-control-input" name={name} data-id={i} id={name} onChange={(e) => this.handleChange(e, 'name', iterable.vaccinationId + '_' + iterable.scheduleDoseNo)} value={iterable.name} /></td>
                                              <td>

                                                {/*  <select {...disableBtnProps} className="table-form-control" onChange={(e) => this.handleChange(e, 'item', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)}>
                                                  <option value="">Select</option>
                                                  {itemData != undefined && itemData != null ?
                                                    itemData?.map((item, i) => {
                                                      let selected = false;;
                                                      if (iterable.item == item.id) {
                                                        selected = true;
                                                      }
                                                      return (<> <option value={item.id} selected={selected}>{item.name}</option> </>)
                                                    }) : ''}

                                                </select> */}
                                                {
                                                  disableBtnProps.disabled == true
                                                    ?
                                                    <select className="table-form-control" {...disableBtnProps} onChange={(e) => this.handleChange(e, 'item', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)}>
                                                      <option value="">Select</option>
                                                      {itemData != undefined && itemData != null ?
                                                        itemData?.map((item, i) => {
                                                          let selected = false;;
                                                          if (iterable.item == item.id) {
                                                            selected = true;
                                                          }
                                                          return (<> <option value={item.id} selected={selected}>{item.name}</option> </>)
                                                        }) : ''}
                                                    </select>
                                                    :
                                                    <select className="table-form-control" {...disableBtnProps} onChange={(e) => this.handleChange(e, 'item', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)}>
                                                      <option value="">Select</option>
                                                      {iterable?.vaccine_list && iterable?.vaccine_list != undefined && iterable?.vaccine_list != null ?
                                                        iterable?.vaccine_list?.map((item, i) => {
                                                          let selected = false;
                                                          if (iterable.item == item.id) {
                                                            selected = true;
                                                          }
                                                          return (<> <option value={item.id} selected={selected}>{item.name}</option> </>)
                                                        }) : ''}
                                                    </select>
                                                }

                                              </td>

                                              <td className="text-center">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                  <input type="checkbox" className="custom-control-input" name={outside} data-id={i} {...disableBtnProps} id={outside} defaultChecked={checked} defaultValue={iterable.outside ? 0 : 'on'} onChange={(e) => this.handleChange(e, 'outside', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} />
                                                  <label className="custom-control-label" htmlFor={outside}></label>
                                                </div>
                                              </td>
                                              <td>
                                                <select
                                                  className="table-form-control"
                                                  onChange={(e) => this.handleChange(e, 'site', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)}
                                                  defaultValue={iterable.vac_site ? iterable.vac_site : ''}
                                                >
                                                  <option value="">Select</option>
                                                  {vaccinationSites?.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.label}</option>
                                                  ))}
                                                </select>
                                              </td>
                                              <td className="datepicker"><DatePicker {...disableBtnProps}  name={givenon} disabledDate={(date) => this.disabledDate(date, iterable.duedate ? iterable.duedate : iterable.due_date)} data-id={i} id={iterable.vaccinationId} onChange={(date, dateString) => this.onChangeDate(date, dateString, iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} defaultValue={iterable.givenon ? moment(iterable.givenon) : ''} format={dateFormat} /></td>

                                              <td>
                                                <input type="text" className="table-form-control remark" {...disableBtnProps} placeholder="Type Batch" maxLength="20" name={batch} data-id={i} id={batch} onChange={(e) => this.handleChange(e, 'batch', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} value={iterable.batch} />
                                              </td>
                                              <td className="datepicker"><DatePicker picker="month" {...disableBtnProps} name={expiry_date} disabledDate={this.disabledPastDate} data-id={i} id={iterable.vaccinationId} onChange={(date, dateString) => this.onExpiryChangeDate(date, dateString, iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} defaultValue={iterable.expiry_date ? moment(iterable.expiry_date) : ''} format="MMM, YYYY" /></td>
                                              <td>
                                                <input type="text" className="table-form-control remark"  {...disableBtnProps} name={remark} data-id={i} id={remark} onChange={(e) => this.handleChange(e, 'remark', iterable.vaccinationId + '_' + iterable.scheduleDoseNo, i)} value={iterable.remark} placeholder="Type Remark" />
                                              </td>
                                              <td>{(iterable.staff==null) ?iterable.id !== 0 ? <Popover content={content} title="Recorded By" placement="topRight" trigger="hover"><span className="icon_info"></span></Popover> : '':iterable.id !== 0 ? <Popover content={content} title="Administered By" placement="topRight" trigger="hover"><span className="icon_info"></span></Popover> : ''}</td>
                                              <td>
                                                
                                            { showIconPlusMain && iterable.id !== 0 && (iterable.scheduleDoseNo == iterable.noOfDoses) ?<a href="javascript:void(0)" onClick={e => this.rowClone(e, i, iterable, iterable.vaccinationId, iterable.id, iterable.emr_vaccination_id)} id={'add_row' + i}>
                                                  <span class="icon-plus"></span>
                                                </a> : '-' }
                                              </td>
                                            </tr>

                                            {iterable.subList && iterable.subList.length > 0 ?

                                              iterable?.subList?.map((newlist, k) => {
                                                let showIconPlus = false;
                                                if(newlist.id > 0 && k == iterable.subList.length-1){
                                                  showIconPlus = true;
                                                }else if(newlist.id > 0 && iterable.subList[k+1]?.id == 0){
                                                  showIconPlus = true;
                                                }

                                                var j = i + k+ newlist.vaccinationId+newlist.dose_number;
                                                let batch = `batch-${j}`, remark = `remark-${j}`, outside_c = `outside_c-${j}`, givenon = `givenon_c-${j}`, name = `name-${j}`, expiry_date = `expirydate-${j}`;
                                                let checked = false;
                                                if (newlist.outside == 1 || newlist.outside == 'on') {
                                                  checked = true;
                                                }
                                                let class_name = '';
                                                let duedate = newlist.duedate ? newlist.duedate : newlist.due_date;
                                                var today = moment();
                                                var dateofvisit = moment(newlist.enddate, 'DD-MM-YYYY');
                                                var ageM = today.diff(dateofvisit, 'days');
                                                if (ageM < 0) {
                                                  class_name = "due-now";
                                                } else if (ageM > 0) {
                                                  class_name = "missed";
                                                }
                                                let vacc_index = null;
                                                const disableBtnProps = {};
                                                if (newlist.id > 0) {
                                                  vacc_index = newlist.id;
                                                }
                                                if ((newlist.allowEdit || (newlist.givenon == null && newlist.outside == 0)) && newlist.createdBy || newlist.creted_by == LS_SERVICE.get('staff_id')) {
                                                  disableBtnProps.disabled = false;
                                                }
                                                else {
                                                  disableBtnProps.disabled = true;
                                                }

                                                if (newlist.id != 0) {
                                                  subContent = (
                                                    <div>
                                                      <p>{(newlist?.staff==null)?'Patient':(newlist?.staff?.designation && newlist?.staff?.designation!=null)?'Dr':''} {newlist.staff ? newlist.staff.first_name : ''} {newlist.staff ? newlist.staff.last_name : ''}, {newlist.givenon ? moment(newlist.givenon).format('D MMM YYYY') : ''}</p>
                                                      <p><b>Recorded On :</b> {moment(newlist.createdAt).format('D MMM YYYY')}</p>
                                                    </div>
                                                  );
                                                }
                                                return (
                                                  <tr style={{backgroundColor: (this.state?.isRequiredSubItem != undefined && this.state?.isRequiredSubItem.indexOf(k) != -1) ? "#fbe1e1" : ''}} key={i} /* style={{ "background": "#ffcccc" }} */>
                                                    <td>{iterable.name} {newlist.scheduleDoseNo}

                                                      <input type="hidden" className="custom-control-input" name={name} data-id={j} id={name} onChange={(e) => this.handleChange(e, 'name', newlist.vaccinationId + '_' + newlist.dose_number)} value={newlist.name} /></td>
                                                    <td>
                                                      {
                                                        disableBtnProps.disabled == true
                                                          ?

                                                          <select className="table-form-control" {...disableBtnProps} onChange={(e) => this.handleChange(e, 'item', newlist.vaccinationId + '_' + newlist.dose_number, i, k, 'clone_list')}>
                                                            <option value="">Select</option>
                                                            {itemData != undefined && itemData != null ?
                                                              itemData?.map((item, i) => {
                                                                let selected = false;;
                                                                if (newlist.item == item.id) {
                                                                  selected = true;
                                                                }
                                                                return (<> <option value={item.id} selected={selected}>{item.name}</option> </>)
                                                              }) : ''}
                                                          </select>
                                                          :
                                                          <select className="table-form-control" {...disableBtnProps} onChange={(e) => this.handleChange(e, 'item', newlist.vaccinationId + '_' + newlist.dose_number, i, k, 'clone_list')}>
                                                            <option value="">Select</option>
                                                            {iterable?.vaccine_list && iterable?.vaccine_list != undefined && iterable?.vaccine_list != null ?
                                                              iterable?.vaccine_list?.map((item, i) => {
                                                                let selected = false;
                                                                if (newlist.item == item.id) {
                                                                  selected = true;
                                                                }
                                                                return (<> <option value={item.id} selected={selected}>{item.name}</option> </>)
                                                              }) : ''}
                                                          </select>
                                                      }

                                                    </td>

                                                    <td className="text-center">
                                                      <div className="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" className="custom-control-input" name={outside_c} data-id={j} {...disableBtnProps} id={outside_c} defaultChecked={checked} defaultValue={newlist.outside ? 0 : 'on'} onChange={(e) => this.handleChange(e, 'outside', newlist.vaccinationId + '_' + newlist.dose_number, i, k, 'clone_list')} />
                                                        <label className="custom-control-label" htmlFor={outside_c}></label>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <select
                                                      className="table-form-control"
                                                      onChange={(e) => this.handleChange(e, 'site', newlist.vaccinationId + '_' + newlist.dose_number, i, k, 'clone_list')}
                                                        defaultValue={newlist.vac_site ? newlist.vac_site : ''}
                                                      >
                                                        <option value="">Select</option>
                                                        {vaccinationSites?.map((item, index) => (
                                                          <option key={index} value={item.id}>{item.label}</option>
                                                        ))}
                                                      </select>
                                                    </td>
                                                    <td className="datepicker"><DatePicker {...disableBtnProps} name={givenon} disabledDate={(date) => this.disabledDate(date, newlist.duedate ? newlist.duedate : newlist.due_date)} data-id={i} id={newlist.vaccinationId} onChange={(date, dateString) => this.onChangeDate(date, dateString, newlist.vaccinationId + '_' + newlist.dose_number, i, k, 'clone_list')} defaultValue={newlist.givenon ? moment(newlist.givenon) : ''} format={dateFormat} /></td>


                                                    <td>
                                                      <input type="text" className="table-form-control remark" {...disableBtnProps} placeholder="Type Batch" maxLength="20" name={batch} data-id={i} id={batch} onChange={(e) => this.handleChange(e, 'batch', newlist.vaccinationId + '_' + newlist.dose_number, i, k, 'clone_list')} value={newlist.batch} />
                                                    </td>
                                                    <td className="datepicker"><DatePicker picker="month" {...disableBtnProps} name={expiry_date} disabledDate={this.disabledPastDate} data-id={i} id={newlist.vaccinationId} onChange={(date, dateString) => this.onExpiryChangeDate(date, dateString, newlist.vaccinationId + '_' + newlist.dose_number, i, k, 'clone_list')} defaultValue={newlist.expiry_date ? moment(newlist.expiry_date) : ''} format="MMM, YYYY" /></td>
                                                    <td>
                                                      <input type="text" className="table-form-control remark"  {...disableBtnProps} name={remark} data-id={j} id={remark} onChange={(e) => this.handleChange(e, 'remark', newlist.vaccinationId + '_' + newlist.dose_number, i, k, 'clone_list')} value={newlist.remark ? newlist.remark : newlist.remarks} placeholder="Type Remark" />
                                                    </td>
                                                    <td>{(newlist.staff==null)?newlist.id !== 0 && newlist.item !== 0 || newlist.outside !== 0 ? <Popover content={subContent} title="Recorded By" placement="topRight" trigger="hover"><span className="icon_info"></span></Popover> : '':newlist.id !== 0 && newlist.item !== 0 || newlist.outside !== 0 ? <Popover content={subContent} title="Administered By" placement="topRight" trigger="hover"><span className="icon_info"></span></Popover> : ''}</td>
                                                    <td>
                                                      {showIconPlus ?<a href="javascript:void(0)" onClick={e => this.rowClone(e, i, iterable, iterable.vaccinationId, iterable.id, iterable.emr_vaccination_id)} id={'add_row' + i}>
                                                      <span class="icon-plus"></span>
                                                      </a> : '' }
                                                    </td>
                                                  </tr>
                                                )


                                              })
                                              : null

                                            }
                                          </>
                                        )
                                      }


                                    }) : <tr><td colSpan="8">No Record Found!</td></tr>}

                                </tbody>
                              </table>
                            </div>
                            <div className="col-12 mb-8">&nbsp;</div>
                            <div className="col-12 mb-8">&nbsp;</div>
                            {OptionalRecord && currentUrl != "Plan" ?
                              <div className="col-12 fixed-cta">
                                <a href="#" className="btn btn-outline-primary px-4 mr-3 mt-2 mb-2 " onClick={e => this.onHandleViewChart(e)}>VIEW CHART</a>

                                <a href="#" type="button" className="btn btn-primary px-5 mt-2 mb-2 " onClick={e => this.handleSubmit(e)}>SAVE</a>


                              </div> :

                              <div className="col-12 fixed-cta">
                                <a href="#" className="btn btn-outline-primary px-4 mr-3 mt-2 mb-2 " onClick={e => this.onHandleViewChart(e)}>VIEW CHART</a>

                                {OptionalRecord && currentUrl === "Plan" ?

                                  <a href="#" type="button" className="btn btn-primary px-5 mt-2 mb-2" onClick={e => this.handleSubmit(e)}>CONTINUE</a>
                                  : <a href="#" onClick={e => this.onContinue(e)} type="button" className="btn btn-primary px-5 mt-2 mb-2" >CONTINUE</a>}


                              </div>}


                          </div>

                        </div>
                      </div>
                      :
                      <div className="col-lg-12">	{message}</div>}

                  </Form>
                </>
                :
                <>

                  <div className="col-lg-12 mt-3" id="printMe">
                    <div className="text-right mb-2 hideWhilePrint">
                      <ul className="immunization_status">
                        <li className="due-now"><i></i> Due Now</li>
                        <li className="missed"><i></i> Missed</li>
                      </ul>
                    </div>
                    <div className="table-responsive">

                      <table className="table table-border">
                        <thead>
                          <tr>
                            <th scope="col" style={{ "text-align": "center" }}>AGE </th>
                            <th scope="col" style={{ "text-align": "center" }}> VACCINE </th>
                            <th scope="col" style={{ "text-align": "center" }}>DUE FROM</th>
                            <th scope="col" style={{ "text-align": "center" }}>GIVEN ON</th>
                            <th scope="col" style={{ "text-align": "center" }}>BATCH</th>
                            <th scope="col" style={{ "text-align": "center" }}>Vaccination Site</th>
                            <th className={this.state.showHide} scope="col" style={{ "text-align": "center" }}>WEIGHT <span className="text-lowercase">(kg)</span></th>
                            <th className={this.state.showHide} scope="col" style={{ "text-align": "center" }}>LENGTH <span className="text-lowercase">(cm)</span></th>
                            <th className={this.state.showHide} scope="col" style={{ "text-align": "center" }}>HEAD CC <span className="text-lowercase">(cm)</span></th>
                          </tr>
                        </thead>
                        <tbody>
                          {vaccinations.map((iap_recommended, i) => <>
                            {iap_recommended.info.map((element, j) => {
                              if (element.hasOwnProperty("vaccine")) {
                                let dueDate = moment(this.state.patient.dob).add(element.startAge, element.startAgeDuration + 's');
                                return (<>
                                  <tr style={{ display: this.state.tillCurrent == 1 ? iap_recommended.age_in_days <= this.state.currentAge ? 'table-row' : 'none' : 'table-row' }}>
                                    {j == 0 ? <td scope="row" rowspan={iap_recommended.info.length + iap_recommended.row_span_count}><strong>{iap_recommended.label}</strong></td> : null}
                                    <td rowspan={((element.vaccine.remarks != '' && element.vaccine.remarks != null) || (element.vaccine.given_outside)) ? 2 : 1}>{element.vaccine.name} {element.scheduleDoseNo}</td>
                                    <td rowspan={((element.vaccine.remarks != '' && element.vaccine.remarks != null) || (element.vaccine.given_outside)) ? 2 : 1}>{dueDate.format('DD/MM/YYYY')}</td>
                                    <td>{element.vaccine.given_on ? moment(element.vaccine.given_on).format('DD/MM/YYYY') : ''}</td>
                                    <td>{element.vaccine.item_id !== 0 && element.vaccine.item_id !== null && element.vaccine.batch !== '' ?
                                      element.vaccine.vac_name + ' (' + element.vaccine.batch + ')' :
                                      element.vaccine.batch != '' ? element.vaccine.batch : ''
                                    }
                                    </td>
                                    <td>{element.vaccine.item_id !== 0 && element.vaccine.item_id !== null && element.vaccine.batch !== '' ?
                                      element.vaccine.vac_name + ' (' + element.vaccine.batch + ')' :
                                      element.vaccine.batch != '' ? element.vaccine.batch : ''
                                    }
                                    </td>
                                    <td>
                                      {element.vaccine.vac_site == "1" ? "Right /Left Antero-lateral thigh Muscle"
                                        : element.vaccine.vac_site == "2" ? "Right /Left Deltoid Muscle"
                                          : element.vaccine.vac_site == "3" ? "Right /Left Triceps Muscle"
                                            : element.vaccine.vac_site == "4" ? "Right /Left Intradermal"
                                              : element.vaccine.vac_site == "5" ? "Right /Left Subcutaneous"
                                                : ""}
                                    </td>
                                    <td className={this.state.showHide}>{element.vaccine.weight}</td>
                                    <td className={this.state.showHide}>{element.vaccine.height}</td>
                                    <td className={this.state.showHide}>{element.vaccine.headCC}</td>
                                  </tr>
                                  {(element.vaccine.remarks != '' && element.vaccine.remarks != null && element.vaccine.given_outside) ?
                                    <tr style={{ display: this.state.tillCurrent == 1 ? iap_recommended.age_in_days <= this.state.currentAge ? 'table-row' : 'none' : 'table-row' }}><td colSpan="6">Remarks - Given Outside. {element.vaccine.remarks}</td></tr> :
                                    (element.vaccine.remarks != '' && element.vaccine.remarks != null && element.vaccine.given_outside === 0) ?
                                      <tr style={{ display: this.state.tillCurrent == 1 ? iap_recommended.age_in_days <= this.state.currentAge ? 'table-row' : 'none' : 'table-row' }}><td colSpan="6">Remarks - {element.vaccine.remarks}</td></tr> :
                                      (element.vaccine.remarks === '' && element.vaccine.remarks === null || element.vaccine.given_outside) ?
                                        <tr style={{ display: this.state.tillCurrent == 1 ? iap_recommended.age_in_days <= this.state.currentAge ? 'table-row' : 'none' : 'table-row' }}><td colSpan="6">Remarks - Given Outside.</td></tr> : null}
                                </>)

                              } else {
                                let dueDate = moment(this.state.patient.dob).add(element.startAge, element.startAgeDuration + 's');
                                let missedClass = '';
                                var today = moment();
                                let end_on = moment(this.state.patient.dob).add(element.endAge, element.endAgeDuration + 's');
                                var dayDifference = today.diff(end_on, 'days');
                                var dayDifferencestartAge = today.diff(dueDate, 'days');
                                if (dayDifferencestartAge + 30 > 0 && dayDifference < 0) { missedClass = "due-now"; }
                                if (dayDifference > 0) { missedClass = "missed"; }
                                return (<>
                                  <tr style={{ display: this.state.tillCurrent == 1 ? iap_recommended.age_in_days <= this.state.currentAge ? 'table-row' : 'none' : 'table-row' }}>
                                    {j == 0 ? <td scope="row" rowspan={iap_recommended.info.length + iap_recommended.row_span_count}><strong>{iap_recommended.label}</strong></td> : null}
                                    <td>{element.name} {element.scheduleDoseNo}</td>
                                    <td className={missedClass}>{dueDate.format('DD/MM/YYYY')}</td>
                                    <td></td>
                                    <td></td>
                                    <td className={this.state.showHide}></td>
                                    <td className={this.state.showHide}></td>
                                    <td className={this.state.showHide}></td>
                                  </tr>
                                </>)
                              }
                            })}
                          </>)}

                          {vaccinationsOptional.map((optional, k) => <>
                            {optional.info.map((element, j) => { 

                              var totalCount=optional.optional_row_count*2;
                              if (element.hasOwnProperty("vaccine")) {
                                
                                return (<>
                                  <tr> 
                                  {j == 0 ? <td scope="row" rowspan={totalCount}><strong>OPTIONAL</strong></td> : null}
                                    <td rowspan={((element.vaccine.remarks != '' && element.vaccine.remarks != null) || (element.vaccine.given_outside)) ? 2 : 1}>{element.vaccine.name} {element.scheduleDoseNo}</td>
                                    <td rowspan={((element.vaccine.remarks != '' && element.vaccine.remarks != null) || (element.vaccine.given_outside)) ? 2 : 1}></td>
                                    <td>{element.vaccine.given_on ? moment(element.vaccine.given_on).format('DD/MM/YYYY') : ''}</td>
                                    <td>{element.vaccine.item_id !== 0 && element.vaccine.item_id !== null ?
                                      element.vaccine.batch !== ''?
                                      element.vaccine.vac_name + ' (' + element.vaccine.batch + ')' :
                                      element.vaccine.batch != '' ? element.vaccine.batch
                                        : element.vaccine.vac_name
                                      : ''
                                    }
                                    </td>
                                    <td>
                                      {element.vaccine.vac_site == "1" ? "Right /Left Antero-lateral thigh Muscle"
                                        : element.vaccine.vac_site == "2" ? "Right /Left Deltoid Muscle"
                                          : element.vaccine.vac_site == "3" ? "Right /Left Triceps Muscle"
                                            : element.vaccine.vac_site == "4" ? "Right /Left Intradermal"
                                              : element.vaccine.vac_site == "5" ? "Right /Left Subcutaneous"
                                                : ""}
                                    </td>
                                    <td className={this.state.showHide}>{element.vaccine.weight}</td>
                                    <td className={this.state.showHide}>{element.vaccine.height}</td>
                                    <td className={this.state.showHide}>{element.vaccine.headCC}</td>
                                  </tr>
                                  {(element.vaccine.remarks != '' && element.vaccine.remarks != null && element.vaccine.given_outside) ?
                                    <tr><td colSpan="6">Remarks - Given Outside. {element.vaccine.remarks}</td></tr> :
                                    (element.vaccine.remarks != '' && element.vaccine.remarks != null && element.vaccine.given_outside === 0) ?
                                      <tr><td colSpan="6">Remarks - {element.vaccine.remarks}</td></tr> :
                                      (element.vaccine.remarks === '' && element.vaccine.remarks === null || element.vaccine.given_outside) ?
                                        <tr><td colSpan="6">Remarks - Given Outside.</td></tr> : null}

                                  {element.vaccine.subList && element.vaccine.subList.length > 0 ?

                                    element?.vaccine?.subList?.map((sub_list, k) => {
                                      if(itemData != undefined && itemData != null)
                                      {
                                        let itemName = itemData.filter(function (e) {
                                          return e;
                                        });
                                      }
                                      var item_name ='';
                                     if(sub_list && sub_list.item_id){
                                      itemData?.map((item, i) => {
                                        if (sub_list.item_id == item.id) {
                                          item_name = item.name;
                                          return item.name;
                                        }
                                      });
                                    }
                                    return (
                                      (sub_list.item_id !== 0 || sub_list.given_outside !== 0)?
                                      <>
                                      <tr>
                                        <td rowspan={((sub_list.remarks != '' && sub_list.remarks != null) || (sub_list.given_outside)) ? 2 : 1}>{sub_list.name} {sub_list.dose_number}</td>
                                        <td rowspan={((sub_list.remarks != '' && sub_list.remarks != null) || (sub_list.given_outside)) ? 2 : 1}></td>
                                        <td>{sub_list.given_on ? moment(sub_list.given_on).format('DD/MM/YYYY') : ''}</td>
                                        <td>{(sub_list.item_id !== 0 && sub_list.item_id !== null)?
                                            (sub_list.batch !== '')?
                                            item_name + ' (' + sub_list.batch + ')' :
                                            sub_list.batch != '' ? sub_list.batch
                                            : item_name
                                          : ''
                                        }
                                        </td>
                                        <td className={this.state.showHide}>{sub_list.weight}</td>
                                        <td className={this.state.showHide}>{sub_list.height}</td>
                                        <td className={this.state.showHide}>{sub_list.headCC}</td>
                                      </tr>

                                      {(sub_list.remarks != '' && sub_list.remarks != null && sub_list.given_outside) ?
                                        <tr><td colSpan="6">Remarks - Given Outside. {sub_list.remarks}</td></tr> :
                                        (sub_list.remarks != '' && sub_list.remarks != null && sub_list.given_outside === 0) ?
                                          <tr><td colSpan="6">Remarks - {sub_list.remarks}</td></tr> :
                                          (sub_list.remarks === '' && sub_list.remarks === null || sub_list.given_outside) ?
                                            <tr><td colSpan="6">Remarks - Given Outside.</td></tr> : null}
                                      </>
                                      : ''
                                    )
                                    })
                                    : null}
                                </>)

                              } else {
                                return (<>
                                  <tr className={j == 0 && optional.optional_row_count > 0 ? '' : 'hideTR'} style={{ display: j == 0 && optional.optional_row_count > 0 ? '' : 'none' }}>
                                    {j == 0 ? <td className="showTD" scope="row" rowspan={totalCount}><strong>OPTIONAL</strong></td> : null}
                                    <td style={{ display: 'none' }} className="hideTD">{element.name} {element.scheduleDoseNo}</td>
                                    <td style={{ display: 'none' }} className="hideTD"></td>
                                    <td style={{ display: 'none' }} className="hideTD"></td>
                                    <td style={{ display: 'none' }} className="hideTD"></td>
                                    <td style={{ display: 'none' }} className={`hideTD ${this.state.showHide}`}></td>
                                    <td style={{ display: 'none' }} className={`hideTD ${this.state.showHide}`}></td>
                                    <td style={{ display: 'none' }} className={`hideTD ${this.state.showHide}`}></td>
                                  </tr>
                                </>)
                              }
                            })}
                          </>)}


                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-12 mb-8">&nbsp;</div>
                  <div className="col-12 mb-8">&nbsp;</div>
                  <div className="col-12 fixed-cta">
                    <div className="custom-control custom-checkbox custom-control-inline">
                      <input type="checkbox" className="custom-control-input" id="current_age" onChange={this.tillCurrentAge} defaultChecked={this.state.tillCurrent} />
                      <label className="custom-control-label" htmlFor="current_age">Print till current age</label>
                    </div>

                    <button type="button" className="btn btn-primary px-5" onClick={e => this.print()}>PRINT</button>
                  </div>
                </>
              }
            </div>

          </div>
        </div>
        {/* For Modal Delete Confirm  */}
        <Modal id="alert_confirm_message_medicine"
          className="alert_confirm_message"
          title={this.state.alertText}
          visible={this.state.isDueOnModelVisible}
          onOk={() => this.rowClone()}
          bodyStyle={{ padding: 0 + 'px' }}
          onCancel={() => this.handleAlertMCancel()}
          closable={false}
          footer={[
            <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={() => this.handleAlertMCancel()} >
              CANCEL
            </Button>,
            <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" onClick={() => this.rowClone()} >
              ADD
            </Button>,
          ]}
        >
        </Modal>
      </>

    )
  }
}

export default VaccinationRecord;
